import { gql } from "@apollo/client";

export default gql`
  query GetPrintableReports(
    $patientId: Int
    $providers: [String!]
    $labnoDigitOnly: String
    $includePdf: Boolean
    $ids: [Int!]
    $allPatients: Boolean
  ) {
    patient(id: $patientId) {
      fullnameTitleCase
      dob
      sex
      mobile
      title
      firstname
      surname
      fullAddress
      street
      city
      state
      postcode
      fullname
      homePhone
      species
    }
    episodereports(
      patientId: $patientId
      providers: $providers
      labnoDigitOnly: $labnoDigitOnly
      includePdf: $includePdf
      ids: $ids
      allPatients: $allPatients
    ) {
      reports {
        reportid
        labnumber
        labid
        dateCollected
        dateCreated
        dateReferred
        dateTested
        interimStatus
        reportingProviderNo
        referredProviderNo
        referredFirstname
        referredSurname
        testcode
        testname
        report
        ccdoctors {
          provider
          firstname
          surname
          title
          fullnameTitleCase
        }
        urno
        pdf
        lab {
          address
          suburb
        }
        reference
        orderingDoctor {
          orderprovier
          orderingclinic
          orderingaddress
          orderingsuburb
          orderingstate
          orderingpostcode
        }
        urgentStatus
      }
    }
  }
`;
