import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { styled } from "@mui/system";

import { HeaderProps, Antibiotic } from "../../antibiogramTypes/AntiBiogramTypes";
import { getAntibioticHeaderStyle } from "../../util/AntiBiogramUtils";
import theme from "../../../../theme";

const CustomTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0",
});
const StyledTableCell = styled(TableCell)({
  border: "1px solid silver",
  fontFamily: "Arial",
  textAlign: "center",
  padding: "5px",
  fontWeight: "bold",
  fontSize: "13px",
  margin: "3px 0",
  color: theme.colorSchemes.light.palette.primary.main,
});

const NoBorderCell = styled(TableCell)({
  border: "none",
  textAlign: "center",
  fontFamily: "Arial",
  padding: "5px",
  fontSize: "13px",
  fontWeight: "bold",
  margin: "3px 0",
  color: theme.colorSchemes.light.palette.primary.main,
});

const VerticalStyledTableCell = styled(StyledTableCell)({
  height: "150px",
  fontSize: "13px",
  color: theme.colorSchemes.light.palette.text.secondary,
  fontWeight: "normal",
  minWidth: "36px",
  "& div": {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    whiteSpace: "nowrap",
    display: "inline-block",
    textAlign: "center",
  },
});

/** HEADER COMPONENT */
const Header: React.FC<HeaderProps> = ({ siteGroup, antibiotics }) => {
  const unrestricted = antibiotics.filter((item: Antibiotic) => item.antibioticType === 1);
  const restricted = antibiotics.filter((item: Antibiotic) => item.antibioticType === 2);

  return (
    <CustomTable>
      <TableHead style={{ borderCollapse: "separate", borderRadius: "10px 10px 10px 10px" }}>
        {/* First Row */}
        <TableRow>
          <NoBorderCell colSpan={3}>{siteGroup} Isolates</NoBorderCell>
          <StyledTableCell
            colSpan={unrestricted.length}
            style={{ background: "white", borderTopLeftRadius: "10px" }}
            variant="body"
          >
            Unrestricted Antibiotics
          </StyledTableCell>
          <StyledTableCell colSpan={restricted.length} style={{ background: "#e6e6e6", borderTopRightRadius: "10px" }}>
            Restricted Antibiotics
          </StyledTableCell>
        </TableRow>

        {/* Second Row */}
        <TableRow>
          <StyledTableCell style={{ borderTopLeftRadius: "10px" }} rowSpan={2}>
            Organism Group
          </StyledTableCell>
          <VerticalStyledTableCell rowSpan={2}>
            <div>No. Isolates</div>
          </VerticalStyledTableCell>
          <VerticalStyledTableCell rowSpan={2}>
            <div>&nbsp;&nbsp;</div>
          </VerticalStyledTableCell>
          {unrestricted.map((antibiotic) => {
            const headerStyle = getAntibioticHeaderStyle(antibiotic.antibioticType, antibiotic.restriction);
            return (
              <VerticalStyledTableCell key={antibiotic.id} style={headerStyle}>
                <div>
                  {antibiotic.name} ({antibiotic.groupCode})
                </div>
              </VerticalStyledTableCell>
            );
          })}
          {restricted.map((antibiotic) => {
            const headerStyle = getAntibioticHeaderStyle(antibiotic.antibioticType, antibiotic.restriction);
            return (
              <VerticalStyledTableCell key={antibiotic.id} style={headerStyle}>
                <div>
                  {antibiotic.name} ({antibiotic.groupCode})
                </div>
              </VerticalStyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </CustomTable>
  );
};
export default Header;
