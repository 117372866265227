import { gql } from "@apollo/client";

export default gql`
  query GetSignalResistanceData($filename: String!) {
    getSignalResistanceData(filename: $filename) {
      code
      error
      message
      data {
        hospital
        copyRightString
        sgFinalList {
          ESBL {
            header
            rowHeader
            signalRest {
              EC {
                orgName
                icOrgName
                totalCount
                positiveCount
                percentage
              }
              KLEB {
                orgName
                icOrgName
                totalCount
                positiveCount
                percentage
              }
            }
          }
          CRE {
            header
            rowHeader
            signalRest {
              orgName
              icOrgName
              totalCount
              positiveCount
              percentage
            }
          }
          VRE {
            header
            rowHeader
            signalRest {
              orgName
              icOrgName
              totalCount
              positiveCount
              percentage
            }
          }
          MRSA {
            header
            rowHeader
            signalRest {
              orgName
              icOrgName
              totalCount
              positiveCount
              percentage
            }
          }
          PS {
            header
            rowHeader
            signalRest {
              PS {
                orgName
                icOrgName
                totalCount
                positiveCount
                percentage
              }
              PI {
                orgName
                icOrgName
                totalCount
                positiveCount
                percentage
              }
              PR {
                orgName
                icOrgName
                totalCount
                positiveCount
                percentage
              }
            }
          }
        }
      }
    }
  }
`;
