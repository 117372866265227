import { gql } from "@apollo/client";

export default gql`
  query getAntibioticAndAntibiogramReport($filename: String!) {
    getAntiBiogramReport(filename: $filename) {
      code
      error
      message
      data {
        hospitalName
        reportParameters {
          quarterData
          ultracode
          siteGroup
          year
          quarter
        }
        totalIsolates
        antibiogramData {
          organismName
          isolatesList {
            organismName
            result
            antimicrobial
            rank
            organismCount
            isolates
            percOfIsoSentvty
            sensitivityCount
          }
          sumOfIsolatesPerAntimicrobial
          sumOfIsolatesPercentage
        }
      }
    }
    getAntibiotics {
      code
      error
      message
      data {
        id
        name
        groupCode
        restriction
        antibioticType
      }
    }
  }
`;
