import React from "react";
import { Box, Typography } from "@mui/material";
import SignalResistanceTable from "./SignalResistanceTable";
import { SignalResistanceCat } from "../../antibiogramTypes/SignalResistanceTypes";

interface SignalResistanceCategoryProps {
  category: SignalResistanceCat;
}

const SignalResistanceCategory: React.FC<SignalResistanceCategoryProps> = ({ category }) => {
  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6" color={"primary"} sx={{ textAlign: "center", fontWeight: "bold", paddingBottom: 2 }}>
        {category.header}
      </Typography>
      <SignalResistanceTable category={category} />
    </Box>
  );
};

export default SignalResistanceCategory;
