import React from "react";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import { SignalResistanceCat, SignalResistanceResult } from "../../antibiogramTypes/SignalResistanceTypes";
import { styled } from "@mui/system";

interface SignalResistanceTableProps {
  category: SignalResistanceCat;
}

// Check if a result has at least one non-null field
const isValidResult = (result: SignalResistanceResult | null): boolean => {
  return result !== null && Object.values(result).some((value) => value !== null);
};

// Check if signalRest contains at least one valid result
const hasValidData = (
  signalRest: Record<string, SignalResistanceResult | null> | SignalResistanceResult | undefined,
): boolean => {
  if (!signalRest) return false;

  if (typeof signalRest === "object" && !Array.isArray(signalRest)) {
    return Object.values(signalRest).some((result) => isValidResult(result));
  }

  return false;
};

// Check if signalRest is a nested object
const isNestedObject = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).some((value) => typeof value === "object" && value !== null);
};

const StyledTableCell = styled(TableCell)({
  border: "1px solid silver",
  fontFamily: "Arial",
  fontSize: "13px",
  textAlign: "center",
  padding: "5px",
  whiteSpace: "nowrap",
});

// Helper function to render a single row
const renderRow = (key: string, result: SignalResistanceResult) => (
  <TableRow key={key}>
    <StyledTableCell>{result.orgName || result.icOrgName || key}</StyledTableCell>
    <StyledTableCell>{result.totalCount ?? "N/A"}</StyledTableCell>
    <StyledTableCell>{result.positiveCount ?? "N/A"}</StyledTableCell>
    <StyledTableCell>{result.percentage ?? "N/A"}</StyledTableCell>
  </TableRow>
);

const SignalResistanceTable: React.FC<SignalResistanceTableProps> = ({ category }) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: "80%", margin: "0 auto", mt: 0, boxShadow: "none" }}>
      <Table className="maintable">
        <TableHead>
          <TableRow>
            {category.rowHeader.map((header, index) => (
              <StyledTableCell key={index} sx={{ fontWeight: "bold", textAlign: "center", fontSize: "13px" }}>
                {header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {category.signalRest && hasValidData(category.signalRest) ? (
            isNestedObject(category.signalRest) ? (
              Object.entries(category.signalRest).map(([key, result]) =>
                result && isValidResult(result) ? renderRow(key, result) : null,
              )
            ) : (
              isValidResult(category.signalRest) &&
              renderRow(category.header, category.signalRest as SignalResistanceResult)
            )
          ) : (
            <TableRow>
              <StyledTableCell
                colSpan={category.rowHeader.length}
                sx={{ textAlign: "center", color: "red", fontStyle: "italic", fontSize: "12px" }}
              >
                There are no results in this table for your facility as there were insufficient pathogens isolated from
                this specimen group during the time period of this report. (Criteria for inclusion: All isolates where
                #&gt; 30 or top X isolates)
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SignalResistanceTable;
