import { makeStyles } from "@mui/styles";
import theme from "../../theme";

export const useDialogStyles = makeStyles(() => ({
  columnA: {
    width: "25%",
  },
  columnB: {
    width: "75%",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    marginBottom: "10px",
  },
  rows: {
    marginBottom: "20px",
  },
  commentBox: {
    maxHeight: "200px",
    overflowY: "scroll",
  },
  dialogBox: {
    width: "380px",
    overflowX: "hidden",
  },
  dialogBoxBig: {
    width: "600px",
    overflowX: "hidden",
  },
  shareContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataGridContainer: {
    height: 150,
    width: "100%",
  },
  searchInput: {
    marginTop: "5px",
  },
}));
