import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../AntiBiogramReport.module.css";
import { styled } from "@mui/system";

import { AntibiogramRow, Antibiotic, Isolate } from "../../antibiogramTypes/AntiBiogramTypes";

const StyledTableCell = styled(TableCell)({
  border: "1px solid silver",
  fontFamily: "Arial",
  fontSize: "13px",
  textAlign: "center",
  padding: "5px",
  whiteSpace: "nowrap",
});

const SecondRow: React.FC<{
  row: AntibiogramRow;
  antibiotics: Antibiotic[];
  rowIndex: number;
  borderBottomRightRadius: string;
}> = ({ row, antibiotics, rowIndex, borderBottomRightRadius }) => {
  return (
    <TableRow>
      {/* First two columns are already merged in FirstRow */}
      {/* Third column: "total #" */}
      <StyledTableCell className={styles.noBgColor}>total #</StyledTableCell>

      {/* Antibiotic columns */}
      {antibiotics.map((antibiotic, index) => {
        const isolate: Isolate | undefined = row.isolatesList.find(
          (isolate) => isolate.antimicrobial === antibiotic.groupCode,
        );
        const organismCount = isolate?.organismCount?.toString() || "-";
        return (
          <StyledTableCell
            key={`${rowIndex}-${antibiotic.id}`}
            className={styles.noBgColor}
            style={antibiotics.length - 1 === index ? { borderBottomRightRadius } : {}}
          >
            {organismCount}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

export default SecondRow;
