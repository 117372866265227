import React, { useState, useContext, useRef } from "react";
import { Box, Typography, Button, Dialog } from "@mui/material";
import styles from "./AddOnTest.module.css";
import get from "lodash/get";
import { Formik } from "formik";
import { AddOnTest, AddOnTestVariables } from "./types/AddOnTest";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import AddOnTestMutation from "./AddOnTestMutation";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLazyQuery } from "@apollo/client";
import { GetPseudonyms, GetPseudonyms_Pseudonyms_pseudonyms } from "../TestSearch/types/GetPseudonyms";
import GetPseudonymsQuery from "../TestSearch/GetPseudonymsQuery";
import lodash from "lodash";
import { formatDate, humanDuration, ausTimeZones } from "../../utils/date";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { eResultsOptions, getSiteOption } from "../../utils/siteOptions";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import theme from "../theme";
import dayjs from "dayjs";

export type addOnTestInputValues = {
  state: string;
  data: string;
  tests: string[];
};

interface AddOnTestPageProps {
  open: boolean;
  fullscreen: boolean;
  sampleAge: string | null;
  ageInHours: number;
  patient: GetPatient_patient | undefined;
  reports: any;
  testNames: string[];
  testRules: any[];
  currentClock: number;
  sendState: string;
  onClose: () => void;
  onClick: () => void;
  linkToForm: boolean;
}

const AddOnTestDialog: React.FC<AddOnTestPageProps> = (props) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const isDesktop = useContext(AppContext).isDesktop;
  const sampleCollected = new Date(String(props.sampleAge)).toISOString().slice(0, 19).replace("T", " ");
  const [inputValue, setInputValue] = React.useState("");
  const [tests, setTests] = React.useState<(string | GetPseudonyms_Pseudonyms_pseudonyms | null)[]>([]);
  const testNames = props.testNames;
  const testRules = props.testRules;
  const labnumber: string = props.reports[0].labnumber ? props.reports[0].labnumber : "";
  const [auditValue, setAuditValue] = React.useState<any>("");
  const eOrderFeature = (getSiteOption(eResultsOptions.EORDER_FEATURE) as string) === "ON";

  const theData: any = {};
  theData.patient = props.patient;
  theData.doctorName = authService.getName();
  theData.providerNo =
    authService.getProviderSelected() !== "0"
      ? authService.getProviderSelected()
      : authService.getProviders().join(", ");
  theData.labNumber = labnumber.substring(0, 11);
  theData.dateCollected = props.reports[0].dateCollected;
  theData.dateCreated = props.reports[0].dateCreated;
  theData.dateReferred = props.reports[0].dateReferred;

  const [options, setOptions] = React.useState<(GetPseudonyms_Pseudonyms_pseudonyms | null)[]>([]);

  const [getPseudonyms, { data, loading: loadingPseudo, error: errorPseudo }] = useLazyQuery<GetPseudonyms>(
    GetPseudonymsQuery,
    {
      variables: { searchTerm: inputValue },
    },
  );

  const autocompleteSearchDebounced = useRef(
    lodash.debounce((q: string) => getPseudonyms({ variables: { searchTerm: q } }), 500),
  );

  const autocompleteSearchThrottled = useRef(
    lodash.throttle((q: string) => getPseudonyms({ variables: { searchTerm: q } }), 500),
  );

  const handleClose = () => {
    setFeedbackMessage("");
    props.onClose();
  };

  const handleClick = () => {
    props.onClick();
  };

  const initialValues: addOnTestInputValues = {
    state: props.sendState ? props.sendState : "",
    data: JSON.stringify(theData),
    tests: [],
  };

  const [addOnTest, { data: addOnTestData, loading, error }] = useMutation<AddOnTest, AddOnTestVariables>(
    AddOnTestMutation,
  );

  if (error) {
    console.error("Error", error);
  }

  if (errorPseudo) {
    console.error("Error", errorPseudo);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setOptions([]);
    }

    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    if (!inputValue) {
      setOptions([]);
    } else {
      if (inputValue.length < 5) {
        autocompleteSearchThrottled.current(inputValue);
      } else {
        autocompleteSearchDebounced.current(inputValue);
      }
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (errorPseudo && errorPseudo.graphQLErrors.length > 0) {
      setOptions([
        {
          __typename: "Pseudonyms",
          test_name: "There was an error",
          panel_code: "",
          report_panelid: -1,
          report_paneln: "",
          all_panels: [],
        },
      ]);
    } else if (!inputValue) {
      setOptions([]);
    } else if (data && data.Pseudonyms && data.Pseudonyms.pseudonyms) {
      if (data.Pseudonyms.pseudonyms.length > 0) {
        setOptions(data.Pseudonyms.pseudonyms);
      } else {
        const notFound: GetPseudonyms_Pseudonyms_pseudonyms[] = [
          {
            __typename: "Pseudonyms",
            test_name: "No tests found",
            panel_code: "",
            report_paneln: "",
            report_panelid: -1,
            all_panels: [],
          },
        ];
        setOptions(notFound);
      }
    } else if (loadingPseudo) {
      setOptions([]);
    }
  }, [inputValue, data, loadingPseudo, errorPseudo]);

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const value = JSON.stringify(auditValue);
  const action = "ADDON_TEST_REQUEST";

  React.useEffect(() => {
    if (!loading && addOnTestData && auditValue) {
      auditLog({
        variables: {
          audit: {
            value,
            action,
          },
        },
      });

      setAuditValue("");
    }
  }, [addOnTestData, loading, auditLog, value, auditValue]);

  const submit = (values: addOnTestInputValues) => {
    const selectedTests = tests.map(function (test) {
      return get(test, "test_name", "");
    });

    const selectedPanels = tests.map(function (test) {
      return get(test, "panel_code", "");
    });

    const selectedRules = testRules
      .map(function (testRule) {
        const testName = get(testRule, "testName");
        let ruleAge = 0;

        if (selectedTests.includes(testName)) {
          ruleAge = get(testRule, "tier1");
        }

        return ruleAge;
      })
      .filter((r) => r !== 0);

    let priority = "normal";

    selectedRules.forEach((r: number) => {
      const currentAge = r - props.ageInHours;

      if (currentAge <= 24) {
        priority = "high";
      }
    });

    const theData = JSON.parse(values.data);
    const currentTime = new Date(
      new Date().toLocaleString("en-US", { timeZone: ausTimeZones(props.sendState) }),
    ).getTime();
    theData.submitDateTime = dayjs(currentTime).format("YYYY-MM-DDTHH:mm:ss.000") + "Z";
    values.data = JSON.stringify(theData);

    onSubmit(values.state, values.data, selectedTests, selectedPanels, priority, addOnTest);
  };

  const onSubmit = async (
    state: string,
    data: string,
    tests: string[],
    panels: string[],
    priority: string,
    addOnTest: MutationTuple<AddOnTest, AddOnTestVariables>[0],
  ) => {
    try {
      if (tests && tests.length > 0) {
        const payload = {
          state: state,
          data: data,
          tests: tests,
          panels: panels,
          priority: priority,
        };

        const result = await addOnTest({
          variables: payload,
        });

        if (get(result, "data.addOnTest") === "Message pushed") {
          setFeedbackMessage("Add-on Test Request sent.");

          payload.data = JSON.parse(payload.data);

          setAuditValue(payload);

          setTimeout(() => {
            setFeedbackMessage("");
            props.onClose();
          }, 3000);
        }
      } else {
        setFeedbackMessage("Please select a minimum of one add-on test.");

        setTimeout(() => {
          setFeedbackMessage("");
        }, 3000);
      }
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      }
    }
  };

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={`${styles.sizeDesktop} ${styles.sizeMobility}`}
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box m={2}>
              <Box ml={0}>
                <DialogBoxTitle
                  title="Add-on Test Request"
                  supplementaryTitle=""
                  marginTop={isDesktop ? "0px" : "30px"}
                  width={300}
                  marginLeft={-2}
                  closeButton
                  handleClose={handleClose}
                />
              </Box>

              <Box display="flex" flexDirection="column">
                <Typography className={styles.menuTitle}>
                  Sample Collected: {formatDate(sampleCollected, "DD MMMM YYYY HH:mm", false)}
                  &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{humanDuration(sampleCollected, props.currentClock)} old
                </Typography>
              </Box>
              <Box>
                <Autocomplete
                  multiple
                  id="tests"
                  options={options}
                  getOptionLabel={(option: any) => {
                    return option && (option as GetPseudonyms_Pseudonyms_pseudonyms).test_name ? option.test_name : "";
                  }}
                  renderOption={(props: any, option: any) => {
                    let result = "";

                    if (option && option.test_name) {
                      const match = testRules.filter((o) => o.testName === option.test_name);

                      result = option.test_name;

                      if (match && match.length > 0) {
                        const days = match[0].tier1 / 24;

                        result += ` - applicable within ${days} day(s)`;
                      } else {
                        result += ` - Not available or past applicable age`;
                      }
                    }

                    return (
                      <Box {...props} style={{ fontSize: "13px" }}>
                        {result}
                      </Box>
                    );
                  }}
                  onClose={() => {
                    setInputValue("");
                  }}
                  onChange={(_, value) => {
                    setTests(value);
                  }}
                  filterSelectedOptions
                  filterOptions={(options) =>
                    options.filter((o) => {
                      const chips = tests.map(function (test) {
                        return get(test, "test_name", "");
                      });

                      return !chips.includes(o?.test_name) && o?.test_name;
                    })
                  }
                  freeSolo
                  noOptionsText="Start typing to show available tests"
                  getOptionDisabled={(option) => {
                    return (
                      (option &&
                        option.test_name &&
                        !testNames
                          .map((e) => {
                            return e.toLowerCase();
                          })
                          .includes(option.test_name.toLowerCase())) ||
                      (errorPseudo && errorPseudo.graphQLErrors.length > 0) ||
                      false
                    );
                  }}
                  ChipProps={{
                    style: {
                      backgroundColor: theme.colorSchemes.light.palette.primary.main,
                      color: "#ffffff",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Available Tests"
                      placeholder="Search Available Tests"
                      onChange={handleInputChange}
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography className={styles.menuTitle}>
                  Add-on tests options are limited (greyed out) according to time based criteria for sample viability.
                  Submission of this form does not guarantee testing as additional assessment criteria may be applied by
                  laboratory staff. For non-applicable tests, please submit a new{" "}
                  {eOrderFeature && props.linkToForm ? (
                    <a href="#" rel="noreferrer" onClick={handleClick}>
                      eOrder
                    </a>
                  ) : (
                    <a
                      href="https://www.clinicallabs.com.au/media/3232/general-pathology-rf-fillable.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      request form
                    </a>
                  )}
                  .
                </Typography>
              </Box>
            </Box>
            <Box ml={2} mt={1} mb={2} display="flex" justifyContent="right">
              <Box className={styles.spaces}>
                <Typography className={styles.MenuItem2}>{feedbackMessage}</Typography>
              </Box>
              <Button type="submit" color="primary" variant="contained" disabled={loading} className={styles.spaces}>
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddOnTestDialog;
