import React from "react";
import TableBody from "@mui/material/TableBody";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import { styled } from "@mui/system";
import { BodyProps } from "../../antibiogramTypes/AntiBiogramTypes";
import { Table } from "@mui/material";

const CustomTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0",
  tableLayout: "fixed",
});

const Body: React.FC<BodyProps> = ({ antibiotics, antibiogramData }) => {
  return (
    <CustomTable>
      <TableBody>
        {antibiogramData.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <FirstRow
              row={row}
              antibiotics={antibiotics}
              rowIndex={rowIndex}
              borderBottomLeftRadius={antibiogramData.length - 1 === rowIndex ? "10px" : "0"}
            />
            <SecondRow
              row={row}
              antibiotics={antibiotics}
              rowIndex={rowIndex}
              borderBottomRightRadius={antibiogramData.length - 1 === rowIndex ? "10px" : "0"}
            />
          </React.Fragment>
        ))}
      </TableBody>
    </CustomTable>
  );
};

export default Body;
