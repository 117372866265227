import { gql } from "@apollo/client";

export default gql`
  query getCpdProviders {
    getCpdProviders {
      providerno
      isvalid
      iscpdenabled
    }
  }
`;
