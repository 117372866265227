import React from "react";
import { Table, TableContainer, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { styled } from "@mui/system";

const LegendTableContainer = styled(TableContainer)({
  maxWidth: "80%",
  margin: "50px auto 20px auto",
  border: "none",
  boxShadow: "none",
});

const StyledTableCell = styled(TableCell)({ border: "none", padding: "10px", verticalAlign: "top" });

const LegendTable: React.FC = () => {
  return (
    <LegendTableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              <Typography variant="body2" fontWeight="bold" color={"grey"}>
                NSW, VIC:
              </Typography>
              <Typography variant="body2" color={"grey"}>
                * Performed by CDS Methodology
              </Typography>
              <Typography variant="body2" color={"grey"}>
                ** Sensitive ≤ 0.125 mg/L
              </Typography>
              <Typography variant="body2" color={"grey"}>
                Intermediate 0.25 - 2 mg/L
              </Typography>
              <Typography variant="body2" color={"grey"}>
                Resistant ≥ 4 mg/L
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="body2" fontWeight="bold" color={"grey"}>
                South Australia:
              </Typography>
              <Typography variant="body2" color={"grey"}>
                * Performed by CLSI Methodology
              </Typography>
              <Typography variant="body2" color={"grey"}>
                ** Sensitive ≤ 0.06 mg/L
              </Typography>
              <Typography variant="body2" color={"grey"}>
                Intermediate 0.125 - 1 mg/L
              </Typography>
              <Typography variant="body2" color={"grey"}>
                Resistant ≥ 2 mg/L
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LegendTableContainer>
  );
};

export default LegendTable;
