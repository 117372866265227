import React, { useCallback, useContext } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "@apollo/client";
import { GenericResolve_fetchDiscoverableUsers } from "./types/GenericResolve";
import GenericMutation from "./GetDiscoverableUsersQuery";
import ShareReportMutation from "./ShareReportMutation";
import debounce from "lodash/debounce";
import { useDialogStyles } from "./styles/dialogStyles";
import { LoadingButton } from "@mui/lab";
import AppContext from "../../context/AppContext";
import { eResultsOptions, getSiteOption } from "../../utils/siteOptions";
import styles from "../EorderDialog/EorderDialog.module.css";

interface DialogBoxShareEmailProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  setOpenShareEmail: (value: boolean) => void;
  reports: Record<string, boolean>;
}

const useStyles = useDialogStyles;
const disclaimerOption = (getSiteOption(eResultsOptions.SHARE_TO_FEATURE) as any)?.value?.disclaimer || "";
const DialogResultsUserShare: React.FC<DialogBoxShareEmailProps> = (props) => {
  const onClose = () => {
    setPage(1);
    props.onClose();
  };
  const classes = useStyles();
  const { setSuccess, setError } = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");

  const [selectedUser, setSelectedUser] = React.useState<{
    name: string;
    address: string;
    providerNumber: string;
  } | null>(null);
  const [message, setMessage] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<any>([]);

  const [serachDoctorReq, { loading: dataLoading }] =
    useMutation<GenericResolve_fetchDiscoverableUsers>(GenericMutation);

  const [shareReport, { loading: loadShare }] = useMutation(ShareReportMutation);

  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      if (searchText) {
        const { data } = await serachDoctorReq({
          variables: {
            name: searchText.toUpperCase() || null,
          },
        });
        if (data?.fetchDiscoverableUsers) {
          const transformedResults = data.fetchDiscoverableUsers.fetchDiscoverableUsers.map((doctor, index) => ({
            id: index,
            name: doctor.fullname || "",
            address: (doctor.address || "") + (doctor.practicename ? ` (${doctor.practicename})` : ""),
            providerNumber: doctor.providerno || "",
          }));
          setSearchResults(transformedResults);
        }
      } else {
        setSearchResults([]);
      }
    }, 500), // 500ms delay
    [], // Empty dependency array since we don't want to recreate this function
  );

  const serachDoctor = (searchText: string) => {
    setSearchText(searchText);
    debouncedSearch(searchText);
  };
  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  // Columns for DataGrid
  const columns = [
    {
      field: "combined",
      flex: 1,
      renderCell: (params: any) => (
        <Box
          sx={{
            width: "100%",
            py: 1, // Add some vertical padding
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{params.row.name}</Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "0.875rem",
            }}
          >
            {params.row.address}
          </Typography>
        </Box>
      ),
    },
  ];
  // Filtered rows based on search
  // const filteredResults = searchResults.filter(
  //   (row) => searchText.trim() !== "" && row.name.toLowerCase().includes(searchText.toLowerCase()),
  // );
  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBoxBig }}
        className={styles.sizeDialogDesltop}
      >
        <DialogTitle className={styles.dialogTitleWithMargin}>
          <DialogBoxTitle
            title={page === 1 ? "Select eResults User" : page === 3 ? "Confirm & Send" : "Complete Message"}
            width={450}
            closeButton
            handleClose={onClose}
            marginLeft={-3}
            closeButtonNoRightPadding
          />
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          {page == 1 && (
            <>
              <Box m={2}>
                <Typography>Enter the name of the clinician you would like to send results to:</Typography>
              </Box>
              <Box m={2}>
                <TextField
                  className={classes.searchInput}
                  label="Search by Name e.g Jane Smith"
                  variant="outlined"
                  fullWidth
                  value={searchText}
                  onChange={(e) => serachDoctor(e.target.value)}
                  autoFocus
                />
                <div className={classes.dataGridContainer}>
                  <DataGrid
                    rows={searchResults}
                    columns={columns}
                    hideFooter
                    loading={dataLoading}
                    onRowClick={(params) => {
                      const user = searchResults.find((row) => row.id === params.row.id);
                      setSelectedUser(user || null);
                      setPage(2); // Move to the next page
                    }}
                    disableColumnMenu
                    columnHeaderHeight={0}
                    sx={(theme) => ({
                      border: "none", // Remove outer border
                      "& .MuiDataGrid-cell": {
                        border: "none", // Remove cell borders
                      },
                      "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add divider between rows
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none", // Remove column separators
                      },
                      "&:hover": {
                        cursor: "pointer", // Change cursor to pointer on hover
                      },
                      "& .MuiDataGrid-overlay": {
                        marginTop: "20px",
                        alignItems: "baseline",
                        color: theme.palette.primary.main,
                        fontSize: "1.2em",
                      },
                    })}
                    localeText={{
                      noRowsLabel: "Sorry, we didn't find anyone in our database.",
                    }}
                  />
                </div>
              </Box>
            </>
          )}{" "}
          {page == 2 && (
            <Box m={2}>
              <Typography mb={1} mt={1}>
                To: {selectedUser?.name}
              </Typography>
              <Box>
                <TextField
                  margin="dense"
                  id="Email"
                  name="Email"
                  label="Message: (Optional)"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={(e: any) => setMessage(e.target.value)}
                  aria-label="Message"
                  autoFocus
                  value={message}
                />
              </Box>
            </Box>
          )}
          {page == 3 && (
            <>
              <Box m={2}>
                <Typography>
                  By clicking ‘Send’ you intend to share the selected results to the following eResults user:
                </Typography>
              </Box>
              <Box m={2}>
                <Typography>{selectedUser?.name}</Typography>
                <Typography>{selectedUser?.address}</Typography>
              </Box>
              <Box m={2}>
                <Typography>
                  <strong>Message:</strong>
                </Typography>
                <Typography style={{ whiteSpace: "pre-line" }}>{message || "No message provided"}</Typography>
                <br />
                <Typography color="textSecondary">
                  {disclaimerOption !== "" ? `Disclaimer: ${disclaimerOption}` : ""}
                </Typography>
              </Box>
            </>
          )}
          <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
            <Button
              onClick={() => {
                if (page != 1) {
                  setPage(page - 1);
                } else {
                  props.onBack();
                }
              }}
              variant="contained"
              color="secondary"
            >
              Back
            </Button>
            {page !== 1 && (
              <LoadingButton
                onClick={() => {
                  if (page === 3) {
                    shareReport({
                      variables: {
                        input: {
                          providerno: selectedUser?.providerNumber,
                          comment: message,
                          reportIds: Object.keys(props.reports)
                            .filter((key) => props.reports[key])
                            .map((key) => Number(key)),
                        },
                      },
                    })
                      .catch((e) => {
                        setError(e.message);
                      })
                      .finally(() => {
                        setSuccess("Report shared successfully");
                        onClose();
                        setPage(1);
                      });
                  } else {
                    setPage(page + 1);
                  }
                }}
                variant="contained"
                color="primary"
                loading={loadShare}
                disabled={loadShare}
              >
                {page === 3 ? "Send" : "Next"}
              </LoadingButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogResultsUserShare;
