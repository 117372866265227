import React, { useContext, useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid, CircularProgress, IconButton, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DownloadIcon from "@mui/icons-material/Download";

import { MainNavigation } from "../../MainNavigation/MainNavigation";
import HospitalReportsStyledHeader from "../HospitalReportsStyledHeader";
import AppContext from "../../../context/AppContext";
import authService from "../../../services/authService";

import GET_SIGNAL_RESISTANCE_REPORT from "./GetSignalResistanceReportQuery";
import GET_SIGNAL_RESISTANCE_CSV from "./GetSignalResistanceReportCSVQuery";
import { SignalResistanceReportProps } from "./antibiogramTypes/SignalResistanceTypes";
import styles from "./SignalResistanceReport.module.css";

import SignalResistanceCategory from "./components/SignalResistance/SignalResistanceCategory";
import LegendTable from "./components/SignalResistance/LegendTable";
import Loading from "../../Loading/Loading";
import { NO_DB_CONNECTION } from "../../../constants";
import ErrorPopover from "../../common/ErrorPopover/ErrorPopover";
import axios from "axios";
import printJS from "print-js";
import { downloadHelper } from "../../../utils/downloadHelper";
import * as htmlToImage from "html-to-image";

const SignalResistanceReportPage: React.FC<SignalResistanceReportProps> = () => {
  const { isDesktop, setError } = useContext(AppContext);
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;

  const { filename } = useParams<{ filename: string }>();

  // LazyQuery: API call only runs on button click
  const [fetchCSVData, { loading: csvLoading, error: csvError }] = useLazyQuery(GET_SIGNAL_RESISTANCE_CSV, {
    fetchPolicy: "network-only",
  });

  // Store CSV data when fetched
  const [csvResults, setCsvResults] = useState<any[]>([]);
  const [shouldDownload, setShouldDownload] = useState(false);

  // Function to trigger API call & prepare CSV download
  const handleDownloadCSV = () => {
    setShouldDownload(false); // Reset download trigger

    fetchCSVData({ variables: { filename } }).then((response) => {
      const fetchedData = response.data?.getSignalResistanceCSVData?.data || [];

      if (fetchedData.length === 0) {
        setError("No data available for download.");
        return;
      }

      setCsvResults(fetchedData);
      setShouldDownload(true); // Trigger download
    });
  };

  // Format Data for CSV
  const csvFormattedData = csvResults.map((row) => ({
    ID: row.id,
    "Organism Name": row.ic_org_name,
    "Organism Code": row.ic_org,
    "Service Date": row.service_date,
    "Lab Request": row.labreq,
    Panel: row.panel,
    "Specimen Site": row.sp_site,
  }));

  // Auto-download CSV when data is ready
  useEffect(() => {
    if (shouldDownload && csvResults.length > 0) {
      const link = document.createElement("a");
      const csvContent = [
        Object.keys(csvFormattedData[0]).join(","), // CSV Headers
        ...csvFormattedData.map((row) => Object.values(row).join(",")), // CSV Rows
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "signal_resistance_data.csv";
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
      setShouldDownload(false);
    }
  }, [shouldDownload, csvResults]);

  // Fetch data from GraphQL
  const { loading, error, data } = useQuery(GET_SIGNAL_RESISTANCE_REPORT, {
    variables: { filename },
    fetchPolicy: "network-only",
  });

  const reportData = data?.getSignalResistanceData?.data;
  const hospitalName = reportData?.hospital;
  const sgFinalList = reportData?.sgFinalList;
  const reportHeading = `Signal Resistance Report ${filename.match(/(\d{4})/) && filename.match(/(\d{4})/).length ? filename.match(/(\d{4})/)[0] : ""}`;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isActioned, setIsActioned] = useState(false);

  const handlePrint = (print: boolean = true) => {
    setIsActioned(true);

    if (print) {
      setIsPrinting(true);
    } else {
      setIsDownloading(true);
    }
  };

  useEffect(() => {
    setError("");
    if (isActioned) {
      const url = `${import.meta.env.VITE_ACL_REPORT_URL}/report`;

      const note = document.getElementById("note") as HTMLElement;
      const legend = document.getElementById("legend") as HTMLElement;
      setError("");

      (async () => {
        const noteDataUrl = await htmlToImage.toPng(note, {
          width: note.scrollWidth + 10,
          height: note.scrollHeight + 40,
          canvasWidth: note.scrollWidth + 10,
          canvasHeight: note.scrollHeight + 40,
        });
        const legendDataUrl = await htmlToImage.toPng(legend, {
          width: legend.scrollWidth + 10,
          height: legend.scrollHeight + 40,
          canvasWidth: legend.scrollWidth + 10,
          canvasHeight: legend.scrollHeight + 40,
        });
        const categoryDataUrls = await Promise.all(
          Object.keys(sgFinalList).map((_, index) => {
            const category = document.getElementById(`category_${index}`) as HTMLElement;
            return htmlToImage.toPng(category, {
              width: category.scrollWidth + 10,
              height: category.clientHeight + 40,
              canvasWidth: category.scrollWidth + 10,
              canvasHeight: category.clientHeight + 40,
            });
          }),
        );
        const downData: any = {
          reports: [
            {
              fileName: filename,
              labid: 1,
              hospital: hospitalName,
              title: reportHeading,
              images: [...categoryDataUrls, noteDataUrl, legendDataUrl],
            },
          ],
        };

        try {
          const response = await axios.post<any>(
            url,
            { template: "signal-resistance-template.html", output: "pdf", data: downData },
            { responseType: "blob" },
          );
          if (isPrinting) {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
              const base64data = (reader.result as string).split(",")[1];
              printJS({ printable: base64data, type: "pdf", base64: true });
              setIsPrinting(false);
            };
          } else {
            downloadHelper(response.data, `${filename}.pdf`);
            setIsDownloading(false);
          }
        } catch {
          setError(NO_DB_CONNECTION);
          setIsActioned(false);
          setIsDownloading(false);
          setIsPrinting(false);
        }

        setIsActioned(false);
      })().catch(() => {
        setError(NO_DB_CONNECTION);
        setIsActioned(false);
        setIsDownloading(false);
        setIsPrinting(false);
      });
    }
  }, [isActioned, isPrinting, isDesktop, mobility]);

  if (error || csvError) {
    setError(NO_DB_CONNECTION);
  }

  return (
    <Box>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation
          subHeader={
            <>
              <Typography className={styles.hospitalReportToolbarHeader}>{hospitalName}</Typography>
              <Typography display={"inline"} sx={{ ml: 1 }}>
                {reportHeading}
              </Typography>
            </>
          }
          showBackButton
          mainHeader={HospitalReportsStyledHeader()}
          backText="Hospital Reports"
          backPath="/hospital-reports"
          hideSeparator={true}
          isHospitalReports
          displayACL={isDesktop}
        />
      </AppBar>

      <div className={styles.hideForPrinting} style={{ height: offsetHeight }} />

      {/* Conditionally render Loading and Error inside JSX, not before return */}
      {loading ? (
        <Loading mt={4} textAlign="center" />
      ) : error || csvError ? (
        <ErrorPopover />
      ) : (
        <>
          {/* Hospital Name */}
          {(mobility || !isDesktop) && (
            <Grid container justifyContent="center" sx={{ textAlign: "center", mt: 3 }}>
              <Grid item xs={12} md={8}>
                <Typography className={styles.hospitalReportHeader}>{hospitalName}</Typography>
                <Typography variant="h5" sx={{ mt: 1 }}>
                  {reportHeading}
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* Print & Download Buttons */}
          <Grid container justifyContent="flex-end" sx={{ mt: 2, px: 3, pb: 2 }} className={styles.hideForPrinting}>
            <Grid item>
              <IconButton
                onClick={handlePrint}
                disabled={isPrinting}
                className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
              >
                {isPrinting || loading ? (
                  <CircularProgress size={24} thickness={5} />
                ) : (
                  <PrintOutlinedIcon titleAccess="Print" color="primary" fontSize={isDesktop ? "large" : "small"} />
                )}
              </IconButton>
              <IconButton
                disabled={isDownloading}
                className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
                onClick={() => handlePrint(false)}
              >
                {isDownloading || loading ? (
                  <CircularProgress size={24} thickness={5} />
                ) : (
                  <SaveAltIcon titleAccess="Download" color="primary" fontSize={isDesktop ? "large" : "small"} />
                )}
              </IconButton>
              {authService.getHospID() === "ACL" && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadCSV}
                  disabled={csvLoading}
                >
                  {csvLoading ? "Fetching CSV..." : "Download CSV"}
                </Button>
              )}
            </Grid>
          </Grid>

          {/* Display Report Categories */}
          {sgFinalList &&
            Object.keys(sgFinalList).map((categoryKey, index) => (
              <div key={categoryKey} id={`category_${index}`}>
                <SignalResistanceCategory category={sgFinalList[categoryKey]} />
              </div>
            ))}

          {/* Note Section */}
          <div id="note">
            <Typography variant="body2" textAlign="center" sx={{ mt: 4, fontWeight: "bold" }}>
              ** Note differing breakpoints for non-meningitis parenteral Rx and meningitis parenteral Rx.
            </Typography>
          </div>

          {/* Legend Section */}
          <div id="legend">
            <LegendTable />
          </div>
        </>
      )}
      <ErrorPopover />
    </Box>
  );
};

export default SignalResistanceReportPage;
