import { gql } from "@apollo/client";
export default gql`
  mutation GenericResolve($name: String) {
    fetchDiscoverableUsers(input: { name: $name }) {
      fetchDiscoverableUsers {
        providerno
        fullname
        address
        practicename
      }
    }
  }
`;
