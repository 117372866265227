import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ReportParams } from "../../antibiogramTypes/AntiBiogramTypes";
import Grid from "@mui/material/Grid";
import styles from "../../AntiBiogramReport.module.css";
import { getSiteGroupComment } from "../../util/AntiBiogramUtils";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

/** Styled Components */
const StyledTableContainer = styled("div")({ marginTop: "15px", width: "100%", overflowX: "auto" });

const StyledTable = styled(Table)({ borderCollapse: "collapse", width: "100%", border: "1px solid silver" });

const StyledTableCell = styled(TableCell)({
  fontFamily: "Arial",
  fontSize: "13px",
  padding: "5px",
  border: "1px solid silver",
});

const KeyCell = styled(TableCell)({ width: "60px", textAlign: "center", border: "1px solid silver" });

const NoteCell = styled(TableCell)({
  textAlign: "left",
  border: "none",
  paddingLeft: "5px",
  fontSize: "13px",
  color: "grey",
});

/** Footer Component */
const Footer: React.FC<{ reportParams: ReportParams }> = ({ reportParams }) => {
  const siteGroupComment = getSiteGroupComment(reportParams.siteGroup);

  return (
    <StyledTableContainer>
      <Grid container>
        <Grid item xs={12} md={9} lg={9}>
          {/* Key Table */}
          <Typography sx={{ fontWeight: "bold", fontSize: "13px" }} color="primary">
            Key:
          </Typography>
          <StyledTable>
            <TableBody>
              <TableRow>
                <KeyCell className={styles.lessthan70}>R</KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>
                  Antibiotics where intrinsic resistance occurs with this organism
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <KeyCell className={styles.lessthan70}>R*</KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>
                  Antibiotics where intrinsic resistance occurs except for high-level aminoglycoside use in synergy with
                  a penicillin
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <KeyCell className={styles.morethan90}></KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>&gt;90% of isolates susceptible</StyledTableCell>
              </TableRow>
              <TableRow>
                <KeyCell className={styles.morethan70}></KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>70-89% of isolates susceptible</StyledTableCell>
              </TableRow>
              <TableRow>
                <KeyCell style={{ background: "#cac7de" }}></KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>Not recommended for children</StyledTableCell>
              </TableRow>
              <TableRow>
                <KeyCell style={{ background: "#e6e6e6" }}></KeyCell>
                <StyledTableCell style={{ fontSize: "13px" }}>Restricted or 2nd Line antibiotics</StyledTableCell>
              </TableRow>
            </TableBody>
          </StyledTable>

          {/* Notes Section */}
          <StyledTable style={{ marginTop: "10px", border: "none", width: "100%" }}>
            <TableBody>
              <TableRow>
                <NoteCell style={{ fontWeight: "bold", paddingLeft: 0 }}>Note:</NoteCell>
                <NoteCell colSpan={2}>
                  1. Data is based on first isolate of a given species per patient per specimen.
                </NoteCell>
              </TableRow>
              <TableRow>
                <NoteCell></NoteCell>
                <NoteCell colSpan={2}>2. {siteGroupComment}</NoteCell>
              </TableRow>
              <TableRow>
                <NoteCell></NoteCell>
                <NoteCell colSpan={2}>
                  3. Antibiotic data where only a subset of isolates have been tested may not be indicative of the true
                  susceptibility because of the effect of cascade testing of more resistant isolates.
                </NoteCell>
              </TableRow>
              <TableRow>
                <NoteCell></NoteCell>
                <NoteCell colSpan={2}>
                  4. Data where &lt; 30 isolates are reported may not be statistically significant and should be treated
                  with caution.
                </NoteCell>
              </TableRow>
              <TableRow>
                <NoteCell></NoteCell>
                <NoteCell colSpan={2}>
                  5. Antimicrobial susceptibility testing method: CDS Method (Disc diffusion) - NSW and Victoria; CLSI
                  disc diffusion - South Australia and Western Australia.
                </NoteCell>
              </TableRow>
              <TableRow>
                <NoteCell></NoteCell>
                <NoteCell colSpan={2}>
                  6. Streptococcus spp. does not include Streptococcus pneumoniae or Beta-haemolytic streptococcus spp.
                  These are reported separately.
                </NoteCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </Grid>
      </Grid>
    </StyledTableContainer>
  );
};

export default Footer;
