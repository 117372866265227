import { gql } from "@apollo/client";

export default gql`
  query GetAntiBiogramCSV($filename: String!) {
    getAntiBiogramCSV(filename: $filename) {
      code
      error
      message
      data {
        id
        icOrgName
        spSiteGroup
        serviceDate
        labreq
        panel
        spSite
        result
        sensitivityResult
        antimicrobial
        cnt
      }
    }
  }
`;
