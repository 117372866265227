import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableContainer,
  useMediaQuery,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DownloadIcon from "@mui/icons-material/Download";
import { MainNavigation } from "../../MainNavigation/MainNavigation";
import HospitalReportsStyledHeader from "../HospitalReportsStyledHeader";
import AppContext from "../../../context/AppContext";
import authService from "../../../services/authService";

// Import Material UI Icons

import { AntiBiogramReportProps } from "./antibiogramTypes/AntiBiogramTypes";
import { getReportHeading } from "./util/AntiBiogramUtils";
import GET_ANTIBIOTIC_AND_ANTIBIOGRAM_REPORT from "./GetAntibioticAndAntibiogramReportQuery";
import GET_ANTIBIOGRAM_CSV from "./GetAntiBiogramCSVQuery";

import Header from "./components/Antibiogram/Header";
import Body from "./components/Antibiogram/Body";
import Footer from "./components/Antibiogram/Footer";

import Loading from "../../Loading/Loading";

import styles from "./AntiBiogramReport.module.css";
import axios from "axios";
import printJS from "print-js";
import { downloadHelper } from "../../../utils/downloadHelper";
import * as htmlToImage from "html-to-image";
import ErrorPopover from "../../common/ErrorPopover/ErrorPopover";
import { NO_DB_CONNECTION } from "../../../constants";

const AntiBiogramReportPage: React.FC<AntiBiogramReportProps> = () => {
  const { isDesktop, setError } = useContext(AppContext);
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  const { filename } = useParams<{ filename: string }>();

  // LazyQuery: API call only runs on button click
  const [fetchCSVData, { loading: csvLoading, error: csvError }] = useLazyQuery(GET_ANTIBIOGRAM_CSV, {
    fetchPolicy: "network-only",
  });

  // Store CSV data when fetched
  const [csvResults, setCsvResults] = useState<any[]>([]);
  const [shouldDownload, setShouldDownload] = useState(false);

  // Function to trigger API call & prepare CSV download
  const handleDownloadCSV = () => {
    setShouldDownload(false); // Reset download trigger

    fetchCSVData({ variables: { filename } }).then((response) => {
      const fetchedData = response.data?.getAntiBiogramCSV?.data || [];

      if (fetchedData.length === 0) {
        alert("No data available for download.");
        return;
      }

      setCsvResults(fetchedData);
      setShouldDownload(true); // Trigger download
    });
  };

  // Format Data for CSV
  const csvFormattedData = csvResults.map((row: any) => ({
    ID: row.id,
    "Organism Name": row.icOrgName,
    "Specimen Site Group": row.spSiteGroup,
    "Service Date": row.serviceDate,
    "Lab Request": row.labreq,
    Panel: row.panel,
    "Specimen Site": row.spSite,
    Result: row.result,
    "Sensitivity Result": row.sensitivityResult ? "Sensitive" : "Resistant",
    Antimicrobial: row.antimicrobial,
    Count: row.cnt,
  }));

  // Auto-download CSV when data is ready
  useEffect(() => {
    if (shouldDownload && csvResults.length > 0) {
      const link = document.createElement("a");
      const csvContent = [
        Object.keys(csvFormattedData[0]).join(","),
        ...csvFormattedData.map((row) => Object.values(row).join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${filename}.csv`;
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
      setShouldDownload(false);
    }
  }, [shouldDownload, csvResults, filename]);

  // Always call useQuery, even if loading or error occurs
  const { loading, error, data } = useQuery(GET_ANTIBIOTIC_AND_ANTIBIOGRAM_REPORT, {
    variables: { filename },
    fetchPolicy: "network-only",
  });

  // Extract fetched data
  const antibiogramReport = data?.getAntiBiogramReport;
  const antibioticsList = data?.getAntibiotics?.data || [];
  const reportData = antibiogramReport?.data;
  const reportParameters = reportData?.reportParameters;

  const [isDownloading, setIsDownloading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isActioned, setIsActioned] = useState(false);

  const reportHeading = getReportHeading(
    reportParameters?.quarter,
    reportParameters?.year,
    reportParameters?.siteGroup,
  );

  const handlePrint = (print: boolean = true) => {
    setIsActioned(true);

    if (print) {
      setIsPrinting(true);
    } else {
      setIsDownloading(true);
    }
  };

  useEffect(() => {
    setError("");
    if (isActioned) {
      const url = `${import.meta.env.VITE_ACL_REPORT_URL}/report`;

      const table = document.getElementById("antibiogram") as HTMLElement;
      const tableFooter = document.getElementById("antibiogram-footer") as HTMLElement;
      setError("");

      (async () => {
        const tableDataUrl = await htmlToImage.toPng(table, {
          width: table.scrollWidth + 10,
          height: table.scrollHeight + 10,
          canvasWidth: table.scrollWidth + 10,
          canvasHeight: table.scrollHeight + 10,
        });
        const tableFooterDataUrl = await htmlToImage.toPng(tableFooter, {
          width: tableFooter.scrollWidth + 10,
          height: tableFooter.scrollHeight + 10,
          canvasWidth: tableFooter.scrollWidth + 10,
          canvasHeight: tableFooter.scrollHeight + 10,
        });
        const downData: any = {
          reports: [
            {
              fileName: filename,
              labid: 1,
              hospital: reportData?.hospitalName,
              title: reportHeading,
              images: [tableDataUrl, tableFooterDataUrl],
            },
          ],
        };

        try {
          const response = await axios.post<any>(
            url,
            { template: "antibiogram-template.html", output: "pdf", data: downData },
            { responseType: "blob" },
          );
          if (isPrinting) {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
              const base64data = (reader.result as string).split(",")[1];
              printJS({ printable: base64data, type: "pdf", base64: true });
              setIsPrinting(false);
            };
          } else {
            downloadHelper(response.data, `${filename}.pdf`);
            setIsDownloading(false);
          }
        } catch {
          setError(NO_DB_CONNECTION);
          setIsActioned(false);
          setIsDownloading(false);
          setIsPrinting(false);
        }

        setIsActioned(false);
      })().catch(() => {
        setError(NO_DB_CONNECTION);
        setIsActioned(false);
        setIsDownloading(false);
        setIsPrinting(false);
      });
    }
  }, [isActioned, isPrinting, isDesktop, mobility]);

  if (error || csvError) {
    setError(NO_DB_CONNECTION);
  }

  return (
    <Box>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation
          subHeader={
            <>
              <Typography className={styles.hospitalReportToolbarHeader}>{reportData?.hospitalName}</Typography>
              <Typography style={{ fontSize: "13px" }} display={"inline"} sx={{ ml: 1 }}>
                {reportHeading}
              </Typography>
            </>
          }
          showBackButton
          mainHeader={HospitalReportsStyledHeader()}
          backText="Hospital Reports"
          backPath="/hospital-reports"
          hideSeparator={true}
          isHospitalReports
          displayACL={isDesktop}
        />
      </AppBar>

      <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />

      {/* Conditionally render Loading and Error inside JSX, not before return */}
      {loading ? (
        <Loading mt={4} textAlign="center" />
      ) : error || csvError ? (
        <ErrorPopover />
      ) : (
        <>
          {/* Centered Heading */}
          {(mobility || !isDesktop) && (
            <Grid container justifyContent="center" sx={{ textAlign: "center", mt: 3 }}>
              <Grid item xs={12}>
                <Typography className={styles.hospitalReportHeader}>{reportData?.hospitalName}</Typography>
                <Typography variant="h5" sx={{ mt: 1 }}>
                  {reportHeading}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-end" sx={{ mt: 2, px: 3, pb: 2 }} className={styles.hideForPrinting}>
            <Grid item>
              <IconButton
                onClick={handlePrint}
                disabled={isPrinting}
                className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
              >
                {isPrinting || loading ? (
                  <CircularProgress size={24} thickness={5} />
                ) : (
                  <PrintOutlinedIcon titleAccess="Print" color="primary" fontSize={isDesktop ? "large" : "small"} />
                )}
              </IconButton>
              <IconButton
                disabled={isDownloading}
                className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
                onClick={() => handlePrint(false)}
              >
                {isDownloading || loading ? (
                  <CircularProgress size={24} thickness={5} />
                ) : (
                  <SaveAltIcon titleAccess="Download" color="primary" fontSize={isDesktop ? "large" : "small"} />
                )}
              </IconButton>
              {authService.getHospID() === "ACL" && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadCSV}
                  disabled={csvLoading}
                >
                  {csvLoading ? "Fetching CSV..." : "Download CSV"}
                </Button>
              )}
            </Grid>
          </Grid>

          {/* Responsive Table Container */}
          <TableContainer
            sx={{
              mt: 2,
              px: 2,
              pb: 4,
              maxWidth: "calc(100% - 40px)",
              overflowX: isSmallScreen ? "auto" : "visible",
              borderRadius: "15px",
            }}
          >
            <Table id="antibiogram" sx={{ borderCollapse: "collapse", width: "100%", minWidth: "900px" }}>
              <Header siteGroup={reportParameters?.siteGroup} antibiotics={antibioticsList} />
              <Body antibiotics={antibioticsList} antibiogramData={reportData?.antibiogramData} />
            </Table>
            <div id="antibiogram-footer">
              <Footer reportParams={reportParameters} />
            </div>
          </TableContainer>
        </>
      )}
      <ErrorPopover />
    </Box>
  );
};

export default AntiBiogramReportPage;
