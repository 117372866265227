import React, { useState, useContext, useRef } from "react";
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha, Theme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, FormikProps } from "formik";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import AppContext from "../../context/AppContext";
import AllowAllPatients from "../AllPatientSearch/AllPatientSearch";
import styles from "../AdvancedSearch/AdvancedSearch.module.css";
import { isOpen, setSearchText, isSearchText, setIsOpen } from "../PatientListPage/PatientListPage";
import AdvancedSearch from "../../model/advanced-search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import advancedSearch from "../../model/advanced-search";
import authService from "../../services/authService";
import paneldept from "../AdvancedSearch/panel-dept.json";
import NotificationBar from "./notificationBar";
import FilterStatusFormControl from "../PatientEpisodesPage/FilterStatusFormControl";
import { SelectChangeEvent } from "@mui/material";
import lodash from "lodash";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import thm from "../theme";
import { PatientContext } from "../../context/PatientsContext";
import dayjs, { Dayjs } from "dayjs";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: "46px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  dontDisplay: {
    display: "none",
  },
  pleaseDisplayClear: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "430px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "257px",
    },
  },
  pleaseDisplayClearMin: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "210px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "175px",
    },
  },

  pleaseDisplayIconForBirthYear: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "170px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "170px",
    },
  },
  pleaseDisplay: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "330px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "257px",
    },
  },
  pleaseDisplayMax: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "400px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "261px",
    },
  },
  pleaseDisplayMin: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "210px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "175px",
    },
  },
  pleaseDisplayWard: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "330px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "262px",
    },
  },
  pleaseDisplayEnterForBirthYear: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "120px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "120px",
    },
  },
  clearButton: {
    cursor: "pointer",
  },
  clearIcon: {
    opacity: "0",
  },
  hide: {
    height: "0px",
    width: "0px",
    opacity: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    display: "flex",
  },

  searchIcon: {
    width: theme.spacing(5),
    height: "56px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  enterIcon: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "31%",
    fontSize: "10px",
    color: "#a3acb3",
  },
  DateRangeIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  yearLabel: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  inputRoot: {
    color: "inherit",
    margin: "5px 0px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.common.white,
    "&:placeholder-shown": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&:-ms-input-placeholder": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
    width: "100%",
    boxShadow: "0 2px 11px 0 rgba(0,0,0,0.1)",
    border: "solid 1px #4e5962",
    height: "30px",
  },
  inputPatientWidthMax: {
    [theme.breakpoints.up("md")]: {
      width: 320,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: 240,
    },
  },
  inputPatientWidthMin: {
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: 162,
    },
  },
  inputYear: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    backgroundColor: "#f2f5fa",
    width: "100%",
    borderRadius: "4px",
    height: "30px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-ms-clear": {
      display: "none",
    },
    MozAppearance: "textfield",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: 200,
    },
  },
  divider: {
    margin: 1,
  },
  inputWard: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.common.white,
    "&:placeholder-shown": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&:-ms-input-placeholder": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
    width: "100%",
    boxShadow: "0 2px 11px 0 rgba(0,0,0,0.1)",
    border: "solid 1px #4e5962",
    height: "30px",
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: 70,
    },
  },
  hasText: {
    backgroundColor: "white",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  error: {
    border: "solid 1px red !important",
  },
}));

export type SearchBarProps = {
  searchtext: string;
  urno: string;
  ward: string;
  reference: string;
  labnumber: string;
  panel: string;
  wardCode: string;
  dateCreated: string;
  status: string[];
  allpatients: boolean;
};

type SearchReportBarProps = {
  allPatients: boolean;
  setAllPatient: (allPatients: boolean) => void;
  setShowLoadMore: (showLoadMore: boolean) => void;
  hideOnScroll: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  advancedClick?: () => void;
  withAdvButton?: boolean;
  isError: boolean;
  setIsError: (isError: boolean) => void;
};

const SearchReportBar: React.FC<SearchReportBarProps> = (props) => {
  const classes = useStyles();
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();
  const [selectedStatuses, setSelectedStatuses] = React.useState<string[]>([]);
  const { setSearchNow } = useContext(PatientContext);

  const location = useLocation();
  const adSearch: advancedSearch = advancedSearch.fromQueryString(queryString.parse(location.search));

  if (authService.getDefaultWardCode() !== "" && !adSearch.ward) {
    adSearch.ward = authService.getDefaultWardCode();
  }

  let isAdSearchPage = false;
  Object.keys(adSearch).forEach((key) => {
    if (typeof (adSearch as any)[key] !== "undefined") {
      isAdSearchPage = true;
    }
  });

  let showClearSearch = false;
  Object.keys(adSearch).forEach((key) => {
    if (props.allPatients && key !== "ward" && typeof (adSearch as any)[key] !== "undefined") {
      showClearSearch = true;
    } else if (!props.allPatients && typeof (adSearch as any)[key] !== "undefined") {
      showClearSearch = true;
    }
  });

  const initialValues: SearchBarProps = {
    urno: adSearch.urno || "",
    ward: adSearch.ward || "",
    reference: adSearch.reference || "",
    labnumber: adSearch.labnumber || "",
    panel: adSearch.panel || "",
    wardCode: adSearch.ward || authService.getDefaultWardCode() ? authService.getDefaultWardCode() : "0",
    searchtext: isSearchText() || adSearch.fullname || "",
    dateCreated: adSearch.dateCreated || "",
    status: [],
    allpatients: adSearch.allpatients || false,
  };

  const [typing, setTyping] = useState(false);

  const [returned, setReturned] = useState(!!isSearchText());

  const today = new Date();
  const oneMonthBeforeToday = new Date();
  oneMonthBeforeToday.setDate(today.getDate() - 30);
  const [maxDate, setMaxDate] = useState<Dayjs | undefined>(dayjs(today));
  const [minDate, setMinDate] = useState<Dayjs | undefined>(dayjs(oneMonthBeforeToday));
  const [dob, setDob] = useState<string | number | Dayjs | Date | null | undefined>(
    adSearch.dob ? dayjs(adSearch.dob as string) : null,
  );
  const [referredStartDate, setreferredStartDate] = useState<string | number | Dayjs | Date | null | undefined>(
    adSearch.referredStartDate ? dayjs(adSearch.referredStartDate as string) : null,
  );
  const [referredEndDate, setreferredEndDate] = useState<string | number | Dayjs | Date | null | undefined>(
    adSearch.referredEndDate ? dayjs(adSearch.referredEndDate as string) : null,
  );
  const [getPanelDepts] = useState<any>(JSON.parse(JSON.stringify(paneldept)));
  const [panelDept, setPanelDept] = useState(adSearch.paneldept ? adSearch.paneldept.toString() : "0");
  const history = useHistory();
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [scrollCount, setScrollCount] = useState(0);
  const [error, setError] = useState("");
  const [errorAllP, setErrorAllP] = useState("");
  const [dateCreated, setDateCreated] = useState(adSearch.dateCreated ? adSearch.dateCreated : "0");
  const [clearVisible, setClearVisible] = useState(isAdSearchPage ? true : false);
  const [isReportPeriodError, setIsReportPeriodError] = useState(false);
  const [isReferredDateError, setIsReferredDateError] = useState(false);

  const wards = authService.getWards();
  if (wards && wards.length) {
    const blankWard = {
      id: "0",
      hospid: "",
      code: "0",
      name: "- All Wards -",
    };
    wards.sort((a, b) => {
      if (a.name && b.name) {
        return a.name > b.name ? 1 : -1;
      } else return -1;
    });
    wards.unshift(blankWard);
  }
  const clinicalTrial = authService.isClinicalTrial() && !authService.isMobility();

  const [wardCode, setWardCode] = useState(
    adSearch.wardCode
      ? adSearch.wardCode.code
      : authService.getDefaultWardCode()
        ? authService.getDefaultWardCode()
        : "0",
  );

  const changeDisplay = () => {
    setTyping(true);
    setReturned(false);
  };

  const hideDisplay = (values: SearchBarProps) => {
    setSearchText(values.searchtext);
    setTyping(false);
  };

  const updateUrl = (values: SearchBarProps) => {
    const updatedSearch = new AdvancedSearch(values.searchtext);
    history.push(`${window.location.pathname}?${updatedSearch.toQueryString()}`);
  };

  const clearSearchNameData = (values: SearchBarProps) => {
    values.searchtext = "";
    setReturned(false);
    updateUrl(values);
    setSearchText("");
    props.setShowLoadMore(true);
    setSearchNow(false);
    sessionStorage.setItem("searchNow", "false");
  };

  const handleDobChange = (date: Date | null) => {
    setDob(date);
  };

  const handleStartDateChange = (date: Date | null) => {
    setreferredStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setreferredEndDate(date);
  };

  const handleClickOpen = () => () => {
    props.setOpen(true);
    setIsOpen(true);
    authService.setAdOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
    setError("");
    setErrorAllP("");
    setIsOpen(false);
    authService.setAdOpen(false);
  };

  const submit = (values: SearchBarProps) => {
    const newAdSearch: advancedSearch = new advancedSearch();

    newAdSearch.fullname = values.searchtext.trim();
    newAdSearch.urno = values.urno.trim().toUpperCase();
    newAdSearch.reference = values.reference.trim();
    newAdSearch.labnumber = values.labnumber.trim();
    newAdSearch.panel = values.panel.trim();
    newAdSearch.allpatients = values.allpatients;
    if (dateCreated !== "0") {
      newAdSearch.dateCreated = dateCreated;
    }

    let isError = false;

    if (dob !== null) newAdSearch.dob = dayjs(new Date(dob as string | number).getTime()).format("YYYY-MM-DD");

    if (!props.allPatients) {
      newAdSearch.ward = values.ward.trim();
    }

    if (panelDept !== "0") newAdSearch.paneldept = parseInt(panelDept);

    if (referredStartDate !== null)
      newAdSearch.referredStartDate = dayjs(new Date(referredStartDate as string | number).getTime()).format(
        "YYYY-MM-DD",
      );

    if (referredEndDate !== null)
      newAdSearch.referredEndDate = dayjs(new Date(referredEndDate as string | number).getTime()).format("YYYY-MM-DD");

    if (wards.length > 0) {
      newAdSearch.wardCode = wards.find((w) => w.code === wardCode);
      newAdSearch.ward = "";
    }

    if (!newAdSearch.wardCode || wards.length === 0) {
      newAdSearch.ward = values.ward.trim() ? values.ward.trim() : authService.getDefaultWardCode();
      if (newAdSearch.ward !== "") {
        newAdSearch.wardCode = undefined;
      }
    }

    if (newAdSearch.allpatients) {
      newAdSearch.ward = undefined;
      newAdSearch.wardCode = undefined;
    }

    if (clinicalTrial) {
      newAdSearch.status = selectedStatuses;
      if (values.status) {
        newAdSearch.status = values.status;
      }
      values.status = newAdSearch.status;
      let newDateCreated = dateCreated;
      if (values.status.length && (newDateCreated === "0" || newDateCreated === undefined)) {
        setDateCreated("12 months");
        newDateCreated = "12 months";
      }
      values.dateCreated = newDateCreated;
      newAdSearch.dateCreated = newDateCreated;
    }

    if (
      newAdSearch.allpatients &&
      !newAdSearch.fullname &&
      !values.labnumber &&
      !values.reference &&
      !values.urno &&
      dob === null &&
      !newAdSearch.wardCode
    ) {
      setError("Please enter at least one of the fields");
      isError = true;
      props.setIsError(true);
      setIsReportPeriodError(false);
      setIsReferredDateError(false);
    } else if ((values.reference || parseInt(panelDept) !== 0 || values.panel) && dateCreated === "0") {
      setError("Please select a Report(s) Period.");
      isError = true;
      props.setIsError(true);
      setIsReportPeriodError(true);
      setIsReferredDateError(false);
    } else if (
      (referredStartDate !== null && referredEndDate === null) ||
      (referredStartDate === null && referredEndDate !== null && !clinicalTrial)
    ) {
      setError("Please select a both Referred Start and End Date(s).");
      isError = true;
      props.setIsError(true);
      setIsReportPeriodError(false);
      setIsReferredDateError(true);
    } else if (clinicalTrial && values.status.length > 0 && values.dateCreated === "0") {
      setError("Please select a Report(s) Period.");
      isError = true;
      props.setIsError(true);
      setIsReportPeriodError(true);
      setIsReferredDateError(false);
    } else {
      setError("");
      isError = false;
      props.setIsError(false);
      setIsReportPeriodError(false);
      setIsReferredDateError(false);
    }

    if (!isError) {
      setSearchNow(true);
      sessionStorage.setItem("searchNow", "true");
      values = initialValues;
      const newquerystring = `?${newAdSearch.toQueryString()}`;

      if (location.search === newquerystring) {
        window.location.reload();
      } else {
        // eslint-disable-next-line no-console

        history.push(`${window.location.pathname}${newquerystring}` + `&isAdvanceSearch=true`);
      }

      if (!isDesktop) {
        props.setOpen(false);
      }

      setClearVisible(true);
      props.setShowLoadMore(true);
    }
  };

  const submitPatient = (values: SearchBarProps) => {
    const patientSearch: AdvancedSearch = new AdvancedSearch();
    patientSearch.fullname = values.searchtext;
    patientSearch.allpatients = props.allPatients;

    if (patientSearch.allpatients && patientSearch.fullname === "") {
      setError("Please enter at least one of the fields");
      props.setIsError(true);
      setIsReportPeriodError(false);
      setIsReferredDateError(false);
      return;
    } else {
      props.setIsError(false);
    }

    if (values.searchtext === "") {
      setReturned(false);
    } else {
      setReturned(true);
      props.setIsError(false);
    }

    setTyping(false);

    let newquerystring = "";
    if (!isOpen()) {
      setSearchText(values.searchtext);
      newquerystring = `?${patientSearch.toQueryString()}`;
    } else {
      let isAdvanceSarchPage = false;
      const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));

      if (props.allPatients) {
        advancedSearch.ward = undefined;
        advancedSearch.wardCode = undefined;
      }

      Object.keys(advancedSearch).forEach((key) => {
        if (typeof (advancedSearch as any)[key] !== "undefined") {
          isAdvanceSarchPage = true;
        }
      });

      if (isAdvanceSarchPage) {
        advancedSearch.allpatients = props.allPatients;
        advancedSearch.fullname = values.searchtext;

        newquerystring = `?${advancedSearch.toQueryString()}`;
      } else {
        setSearchText(values.searchtext);
        newquerystring = `?${patientSearch.toQueryString()}`;
      }
    }

    if (location.search === newquerystring) {
      window.location.reload();
    } else {
      history.push(`${window.location.pathname}${newquerystring}`);
    }
    setSearchNow(true);
    sessionStorage.setItem("searchNow", "true");
  };

  const selectPanel = (event: SelectChangeEvent<string>) => {
    setPanelDept(event.target.value as string);
  };

  const selectWard = (event: SelectChangeEvent<string>) => {
    setWardCode(event.target.value as string);
  };

  const selectdateCreated = (event: SelectChangeEvent<string>) => {
    if (event.target.value !== undefined) {
      const selectedValue = event.target.value as string;
      setDateCreated(selectedValue);

      if (selectedValue !== "0") {
        const monthsBeforeToday = new Date();
        let newMinDate = new Date(monthsBeforeToday);
        let newMaxDate = new Date(today);

        switch (selectedValue) {
          case "12 months":
            monthsBeforeToday.setDate(today.getDate() - 365);
            newMinDate = new Date(monthsBeforeToday);
            break;
          case "6 months":
            monthsBeforeToday.setDate(today.getDate() - 180);
            newMinDate = new Date(monthsBeforeToday);
            break;
          case "3 months":
            monthsBeforeToday.setDate(today.getDate() - 90);
            newMinDate = new Date(monthsBeforeToday);
            break;
          case "1 months":
            monthsBeforeToday.setDate(today.getDate() - 30);
            newMinDate = new Date(monthsBeforeToday);
            break;
          default:
            newMinDate = new Date(selectedValue + "-01-01");
            newMaxDate = new Date(selectedValue + "-12-31");
        }

        setMinDate(dayjs(newMinDate));
        setMaxDate(dayjs(newMaxDate));
      }
    }
  };

  const archivedYear: any[] = [];
  const curYear = new Date().getFullYear();
  let startYear = 2016;

  while (startYear < curYear) {
    archivedYear.push(startYear++);
  }

  archivedYear.reverse();

  const onReset = (values: SearchBarProps) => {
    setSearchNow(false);
    sessionStorage.setItem("searchNow", "false");
    values.labnumber = "";
    values.panel = "";
    values.reference = "";
    values.urno = "";
    values.ward = authService.getDefaultWardCode() ? authService.getDefaultWardCode() : wards.length ? "0" : "";
    adSearch.fullname = "";
    adSearch.labnumber = "";
    adSearch.panel = "";
    adSearch.reference = "";
    adSearch.urno = "";
    adSearch.ward = authService.getDefaultWardCode() ? authService.getDefaultWardCode() : "0";
    adSearch.dateCreated = "0";
    setPanelDept("0");
    setDob(null);
    props.setShowLoadMore(true);
    setSearchText("");
    values.searchtext = "";
    authService.clearDefaultWardCode();
    setWardCode(authService.getDefaultWardCode() ? authService.getDefaultWardCode() : "0");
    setDateCreated("0");
    setreferredStartDate(null);
    setreferredEndDate(null);
    setMinDate(dayjs(oneMonthBeforeToday));
    setMaxDate(dayjs(today));
    setSelectedStatuses([]);
    history.push(`${window.location.pathname}`);
    props.setIsError(false);
  };

  const formikRef = useRef<FormikProps<SearchBarProps>>(null);
  const handleAllPatientChange = (isAllPatient: boolean) => {
    props.setAllPatient(isAllPatient);
    if (formikRef.current) {
      formikRef.current.values.allpatients = isAllPatient;
      if (
        formikRef.current.values.searchtext ||
        formikRef.current.values.urno ||
        formikRef.current.values.labnumber ||
        formikRef.current.values.panel ||
        formikRef.current.values.reference ||
        formikRef.current.values.ward ||
        formikRef.current.values.wardCode !== "0"
      ) {
        submit(formikRef.current.values);
      }
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<typeof selectedStatuses>) => {
    const {
      target: { value },
    } = event;
    const newStatus =
      typeof value === "string"
        ? value.split(",")
        : value.filter(function (el) {
            return el != "";
          });
    setSelectedStatuses(newStatus);
    debouncedSubmit(newStatus);
  };

  useScrollPosition(
    ({ prevPos, currPos }: { prevPos: any; currPos: any }) => {
      const isShow = currPos.y > prevPos.y - 20;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
        setScrollCount(currPos.y + 20);
      }
    },
    [hideOnScroll, scrollCount],
  );

  const debouncedSubmit = useRef(
    lodash.debounce((val: string[]) => {
      if (formikRef.current) {
        formikRef.current.values.status = val;
        submit(formikRef.current.values);
      }
    }, 800),
  ).current;

  return (
    <Formik initialValues={initialValues} onSubmit={submit} onReset={onReset} innerRef={formikRef}>
      {({ values, handleSubmit, handleChange, handleReset }: any) => (
        <Box my={2} display="flex" justifyContent="space-between">
          <Box width={1} ml={isDesktop ? 10 : 0}>
            <form onSubmit={handleSubmit} id="searchReportBarForm">
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item xs={12} sm={clinicalTrial ? 7 : 12} md={clinicalTrial ? 9 : 12}>
                  <Box
                    display={isDesktop ? "flex" : ""}
                    flexDirection="row"
                    alignItems="center"
                    mt={isMobility ? -2 : 0}
                    ml={isMobility ? -0.8 : 0}
                  >
                    <div>
                      <TextField
                        placeholder="Patient name"
                        id="searchtext"
                        name="searchtext"
                        variant="outlined"
                        className={isDesktop ? styles.mainInputs : styles.mainInputsMobile}
                        error={props.isError && !isReportPeriodError && !isReferredDateError}
                        onChange={handleChange}
                        onBlur={() => {
                          hideDisplay(values);
                        }}
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            //Enter Key
                            submitPatient(values);
                            e.preventDefault();
                          } else {
                            changeDisplay();
                          }
                        }}
                        size="medium"
                        value={values.searchtext}
                        InputProps={{
                          className: classes.input,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {typing ? (
                                <KeyboardReturnIcon />
                              ) : returned ? (
                                <ClearIcon
                                  className={classes.clearButton}
                                  onClick={() => {
                                    clearSearchNameData(values);
                                  }}
                                />
                              ) : (
                                <ClearIcon className={classes.clearIcon} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <AllowAllPatients
                      allPatients={props.allPatients}
                      setAllPatient={handleAllPatientChange}
                      isAdvanceSearch={false}
                      isOpen={props.open}
                    />
                    <Button
                      className={
                        !props.withAdvButton
                          ? styles.hide
                          : props.open
                            ? styles.hide
                            : styles.advanceSearchButtonOutside
                      }
                      onClick={handleClickOpen()}
                      style={{ marginLeft: isDesktop ? "16px" : "8px" }}
                    >
                      Advanced Search
                    </Button>
                    {clearVisible && !props.open && showClearSearch && props.withAdvButton && (
                      <Button className={`${styles.clearButtonInline}`} onClick={handleReset}>
                        Clear Search
                      </Button>
                    )}
                  </Box>
                </Grid>
                {clinicalTrial && (
                  <Grid item xs={12} sm={4} md={3}>
                    <Box width={"95%"} display={"flex"} alignItems={"center"} marginTop={"8px"}>
                      <FilterStatusFormControl
                        selectedStatuses={selectedStatuses}
                        handleStatusChange={handleStatusChange}
                        setSelectedStatuses={setSelectedStatuses}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box mt={!isDesktop || isMobility ? 1.5 : 0} mr={1}>
                <Box className={`${styles.advanceBox}`}>
                  {!props.withAdvButton && (
                    <Button
                      className={`${props.open ? styles.hide : styles.advanceSearchButton}`}
                      onClick={handleClickOpen()}
                    >
                      Advanced Search
                    </Button>
                  )}
                  {clearVisible && !props.open && showClearSearch && !props.withAdvButton && (
                    <Button className={`${styles.clearButtonInline}`} onClick={handleReset}>
                      Clear Search
                    </Button>
                  )}
                </Box>
                <Collapse in={props.open} aria-labelledby="advanced-search">
                  <IconButton
                    onClick={handleClose}
                    className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
                  >
                    <ClearIcon fontSize="small" /> Close
                  </IconButton>
                  <Grid
                    container
                    spacing={1}
                    className={!isDesktop || isMobility ? styles.advBoxLeftMobile : styles.advBoxLeft}
                  >
                    <Grid
                      container
                      spacing={1}
                      style={{
                        backgroundColor: thm.colorSchemes.light.palette.primary.light,
                        borderRadius: "5px",
                        paddingLeft: "5px",
                        marginBottom: "10px",
                      }}
                      className={!isDesktop || isMobility ? styles.advBoxLeftMobile : styles.advBoxLeft}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ color: thm.colorSchemes.light.palette.primary.main, fontStyle: "italic" }}
                      >
                        <Typography>QUICK SEARCH</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            label="Date Of Birth"
                            value={dob as Dayjs | null | undefined}
                            onChange={(newValue: Dayjs | null) => {
                              if (newValue && newValue.isValid()) {
                                handleDobChange(newValue as unknown as Date | null);
                              }
                            }}
                            slotProps={{
                              textField: {
                                error: props.isError && !isReportPeriodError && !isReferredDateError,
                                placeholder: "DD/MM/YYYY",
                                size: "small",
                                className: styles.dateField,
                              },
                            }}
                            className={`${styles.detailInputs}`}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          margin="dense"
                          id="urno"
                          label="UR/MR no."
                          type="text"
                          fullWidth
                          name="urno"
                          value={values.urno}
                          onChange={handleChange}
                          variant="outlined"
                          className={`${styles.detailInputs}`}
                          size="small"
                          error={props.isError && !isReportPeriodError && !isReferredDateError}
                        />
                      </Grid>
                      {!props.allPatients && (
                        <Grid item xs={6} md={3}>
                          {wards && wards.length ? (
                            <Select
                              id="wardCode"
                              value={wardCode}
                              onChange={selectWard}
                              variant="outlined"
                              className={`${isDesktop ? styles.selectInput : styles.selectInputMobile} ${
                                props.isError && !isReportPeriodError && !isReferredDateError
                                  ? styles.error
                                  : styles.detailInputs
                              }`}
                              name="wardCode"
                            >
                              {wards.map((w) => (
                                <MenuItem key={w.code} value={w.code}>
                                  {w.name || w.code}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextField
                              margin="dense"
                              id="ward"
                              label="Ward"
                              type="text"
                              fullWidth
                              name="ward"
                              value={values.ward}
                              onChange={handleChange}
                              variant="outlined"
                              className={`${styles.detailInputs}`}
                              size="small"
                              error={props.isError && !isReportPeriodError && !isReferredDateError}
                            />
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12} md={3}>
                        <TextField
                          margin="dense"
                          id="labnumber"
                          label="Lab Number"
                          type="text"
                          fullWidth
                          name="labnumber"
                          value={values.labnumber}
                          onChange={handleChange}
                          variant="outlined"
                          className={`${styles.detailInputs}`}
                          size="small"
                          error={props.isError && !isReportPeriodError && !isReferredDateError}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} container justifyItems="flex-end" alignItems="center">
                      <Typography style={{ color: "#666666", fontSize: "0.8em" }}>
                        Searching via any of these fields requires selecting a report period below
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        margin="dense"
                        id="reference"
                        label="Reference"
                        type="text"
                        fullWidth
                        name="reference"
                        value={values.reference}
                        onChange={handleChange}
                        variant="outlined"
                        className={`${styles.detailInputs}`}
                        size="small"
                        error={props.isError && !isReportPeriodError && !isReferredDateError}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Select
                        id="paneldept"
                        value={panelDept}
                        onChange={selectPanel}
                        variant="outlined"
                        className={`${isDesktop ? styles.selectInput : styles.selectInputMobile} ${
                          styles.detailInputs
                        }`}
                        size="small"
                        error={props.isError && !isReportPeriodError && !isReferredDateError}
                      >
                        <MenuItem value={0}>Panel Department</MenuItem>
                        {getPanelDepts["panel_departments"].map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        margin="dense"
                        id="panel"
                        label="Panel"
                        type="text"
                        fullWidth
                        name="panel"
                        value={values.panel}
                        onChange={handleChange}
                        variant="outlined"
                        className={`${styles.detailInputs}`}
                        size="small"
                        error={props.isError && !isReportPeriodError && !isReferredDateError}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={12}>
                      <hr />
                    </Grid>
                    <Grid item xs={12} md={3} container justifyItems="flex-end" alignItems="center">
                      <Typography style={{ color: "#666666", fontSize: "0.8em" }}>
                        The report period can be refined further by selecting a start and end date
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Select
                        id="dateCreated"
                        value={dateCreated}
                        onChange={selectdateCreated}
                        variant="outlined"
                        className={`${isDesktop ? styles.selectInput : styles.selectInputMobile} ${
                          styles.detailInputs
                        }`}
                        error={isReportPeriodError}
                      >
                        <MenuItem key="0" value="0">
                          Select a Report(s) Period
                        </MenuItem>
                        <MenuItem key="1 months" value="1 months">
                          Current Reports for last 1 Month
                        </MenuItem>
                        <MenuItem key="3 months" value="3 months">
                          Current Reports for last 3 Months
                        </MenuItem>
                        <MenuItem key="6 months" value="6 months">
                          Current Reports for last 6 Months
                        </MenuItem>
                        <MenuItem key="12 months" value="12 months">
                          Current Reports for last 12 Months
                        </MenuItem>
                        <ListSubheader>Archive</ListSubheader>
                        {archivedYear.map((year) => {
                          return (
                            <MenuItem key={year} value={year}>
                              January - December {year}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label="Referred Start Date"
                          value={referredStartDate as Dayjs | null | undefined}
                          onChange={(newValue: Dayjs | null) => {
                            handleStartDateChange(newValue as Date | null);
                          }}
                          slotProps={{
                            textField: {
                              error: isReferredDateError,
                              placeholder: "DD/MM/YYYY",
                              size: "small",
                              className: styles.dateField,
                              fullWidth: true,
                            },
                          }}
                          className={`${styles.DateInput}`}
                          {...(dateCreated !== "0" ? { maxDate: maxDate, minDate: minDate } : {})}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label="Referred End Date"
                          value={referredEndDate as Dayjs | null | undefined}
                          onChange={(newValue: Dayjs | null) => {
                            handleEndDateChange(newValue as Date | null);
                          }}
                          slotProps={{
                            textField: {
                              error: isReferredDateError,
                              placeholder: "DD/MM/YYYY",
                              size: "small",
                              className: styles.dateField,
                              fullWidth: true,
                            },
                          }}
                          className={`${styles.DateInput}`}
                          {...(dateCreated !== "0" ? { maxDate: maxDate, minDate: minDate } : {})}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    {props.isError && <p className={`${styles.errorMessage}`}>{error}</p>}
                    {props.isError && <p className={`${styles.errorMessage}`}>{errorAllP}</p>}

                    <Button type="submit" variant="contained" color="primary" className={`${styles.submitButton}`}>
                      Search
                    </Button>
                  </DialogActions>
                  <Divider className={`${clearVisible && props.open ? styles.divider : styles.dividerHide}`} />
                  {clearVisible && showClearSearch && (
                    <Box>
                      <Button className={`${styles.clearButton}`} onClick={handleReset}>
                        Clear Search
                      </Button>
                    </Box>
                  )}
                </Collapse>
              </Box>
            </form>
          </Box>
          {!isMobility && (
            <Box>
              <NotificationBar />
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default SearchReportBar;
