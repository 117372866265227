import React, { useState } from "react";
import { Typography, Box, useMediaQuery, CircularProgress } from "@mui/material";
import styles from "./PatientEpisodeHeader.module.css";
import { GetPatientEpisodes_patientepisodes_episodes } from "../PatientDetailsPage/types/GetPatientEpisodes";
import { formatDate } from "../../utils/date";
import AddOnTestDialog from "../AddOnTest/AddOnTest";
import theme from "../theme";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import { GetAddOnTests } from "../AddOnTest/types/GetAddOnTests";
import GetAddOnTestsQuery from "../AddOnTest/GetAddOnTestsQuery";
import { useQuery } from "@apollo/client";
import authService from "../../services/authService";
import { PlusMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import { GetStateFromSuburb } from "../AddOnTest/types/GetStateFromSuburb";
import GetStateFromSuburbQuery from "../AddOnTest/GetStateFromSuburbQuery";
import { useLazyQuery } from "@apollo/client";
import { ausTimeZones, getDiffInHours } from "../../utils/date";
import { INTERUM_STATUS } from "../../constants";
import EorderDialog from "../EorderDialog/EorderDialog";
import { eResultsOptions, getSiteOption, showEorderButton } from "../../utils/siteOptions";
import { validateProvider } from "../../utils/provider";
import { isTenantVet } from "../../services/tenantService";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export type PatientEpisodeHeaderProps = {
  episode: GetPatientEpisodes_patientepisodes_episodes;
  active: boolean;
  mostRecent: boolean;
  sortBy: string;
  showRemovedPanels: boolean;
  onHeaderClick?: (event: React.SyntheticEvent) => void;
  isDesktop: boolean;
  patient?: GetPatient_patient | undefined;
};

type StatusCounterProps = {
  text: string;
  counter: number;
  pending: boolean;
  active: boolean;
  mt?: number;
  mb?: number;
};

const StatusCounter: React.FC<StatusCounterProps> = (props) => (
  <Box
    className={`${styles.statusCounterOuterBox} ${props.active ? styles.statusCounterOuterBoxActive : ""}`}
    mt={props.mt}
    mb={props.mb}
  >
    <Typography
      display={"inline"}
      className={props.active ? styles.statusCounterLabelActive : styles.statusCounterLabel}
      variant={"body2"}
    >
      {props.text}
    </Typography>
    <Box
      className={`${styles.statusCounterText} ${
        isTenantVet()
          ? props.pending
            ? styles.statusCounterTextPendingVet
            : styles.statusCounterTextMainVet
          : props.pending
            ? styles.statusCounterTextPending
            : styles.statusCounterTextMain
      }`}
    >
      <Typography display={"inline"} className={styles.statusCounterTextItem} variant={"body2"}>
        {props.counter}
      </Typography>
    </Box>
  </Box>
);

const PatientEpisodeHeader: React.FC<PatientEpisodeHeaderProps> = (props) => {
  const [openAddOnTestDialog, setOpenAddOnTestDialog] = useState(false);
  const [addOnTests, setAddOnTests] = React.useState<string[]>([]);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentClock, setCurrentClock] = React.useState<number>(0);
  const [sendState, setSendState] = React.useState<string>("");
  const [addOnTestRules, setAddOnTestRules] = React.useState<any[]>([]);
  const [ageInHours, setAgeInHours] = React.useState<number>(0);
  const [openEorderDialog, setOpenEorderDialog] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const validProvider = validateProvider(props.episode.panels[0].reportingProviderNo as string);
  const eOrderFeature = (getSiteOption(eResultsOptions.EORDER_FEATURE) as string) === "ON";
  const [showGenEorderButton, setShowGenEorderButton] = React.useState(false);
  const siteOptions = getSiteOption(eResultsOptions.EORDER_BLACKLIST) as string[];

  const mobility = authService.isMobility();

  const reported = props.episode.panels.filter(
    (p) =>
      p.interimStatus === INTERUM_STATUS.Final ||
      p.interimStatus === INTERUM_STATUS.FinalCorrected ||
      p.interimStatus === INTERUM_STATUS.Preliminary,
  );
  const pending = props.episode.panels.filter(
    (p) =>
      p.interimStatus !== INTERUM_STATUS.Final &&
      p.interimStatus !== INTERUM_STATUS.FinalCorrected &&
      p.interimStatus !== INTERUM_STATUS.Preliminary &&
      p.interimStatus !== INTERUM_STATUS.Cancelled,
  );
  const deleted = props.episode.panels.filter((p) => p.interimStatus === INTERUM_STATUS.Cancelled);
  let sortedDate = "";
  const dateFormat = "DD MMM YYYY";

  if (props.episode.panels.length > 0) {
    sortedDate = formatDate(props.episode.panels[0].dateCreated, dateFormat, false);

    if (props.sortBy !== "" || props.sortBy !== undefined || props.sortBy != null) {
      if (props.sortBy === "dateCollected") {
        sortedDate = formatDate(props.episode.panels[0].dateCollected, dateFormat, true);
      } else if (props.sortBy === "dateCreated") {
        sortedDate = formatDate(props.episode.panels[0].dateCreated, dateFormat, false);
      } else if (props.sortBy === "dateReferred") {
        sortedDate = formatDate(props.episode.panels[0].dateReferred, dateFormat, true);
      }
    }
  }

  const [getAddOnTests, { data: addOnTestData, loading: addOnTestLoading, error: addOnTestError }] =
    useLazyQuery<GetAddOnTests>(GetAddOnTestsQuery, {
      variables: {
        state: "",
        hours: 0,
      },
    });

  React.useEffect(() => {
    if (props.episode.panels[0].reportingProviderNo && props.episode.panels[0].reportingProviderNo !== "") {
      setShowGenEorderButton(showEorderButton(siteOptions, props.episode.panels[0].reportingProviderNo));
    }

    if (addOnTestData && addOnTestData.getAddOnTests.length > 0) {
      const testNames = addOnTestData.getAddOnTests.map((test) => test.testName);

      setAddOnTests(testNames);
      setAddOnTestRules(addOnTestData.getAddOnTests);
    } else {
      setAddOnTests([]);
      setAddOnTestRules([]);
    }
  }, [addOnTestData]);

  if (addOnTestError) {
    console.error("Error", addOnTestError);
  }

  const sendSuburb = reported.length > 0 ? reported.filter((o) => o.suburb) : pending.filter((o) => o.suburb);

  const ignoreProviders = getSiteOption(eResultsOptions.ADDON_IGNORE) as string[];
  const isIgnore = ignoreProviders.includes(
    (reported[0]?.referredProviderNo ? reported[0]?.referredProviderNo : "") as string,
  );

  const {
    data: suburbStateData,
    loading: suburbStateLoading,
    error: suburbStateError,
  } = useQuery<GetStateFromSuburb>(GetStateFromSuburbQuery, {
    variables: {
      suburb: sendSuburb.length > 0 ? sendSuburb[0].suburb : "",
    },
    skip: sendSuburb.length === 0,
  });

  React.useEffect(() => {
    if (!isIgnore && suburbStateData && !suburbStateLoading) {
      let suburbState = authService.getState();

      if (
        suburbStateData.getStateFromSuburb &&
        suburbStateData.getStateFromSuburb.state &&
        suburbStateData.getStateFromSuburb.state !== ""
      ) {
        suburbState = suburbStateData.getStateFromSuburb.state;
      }

      if (suburbState.trim() !== "" && suburbState.trim() != null) {
        const currentTime = dayjs(dayjs().tz(ausTimeZones(suburbState))).valueOf();
        const dateCollected =
          reported.length > 0
            ? new Date(String(reported[0].dateCollected)).toISOString().slice(0, 19).replace("T", " ")
            : new Date(String(pending[0].dateCollected)).toISOString().slice(0, 19).replace("T", " ");
        const sampleAge = getDiffInHours(dateCollected, currentTime);
        // sampleAge = 1;
        setAgeInHours(sampleAge);
        setCurrentClock(currentTime);
        setSendState(suburbState);
        getAddOnTests({
          variables: {
            state: suburbState,
            hours: sampleAge,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [suburbStateData, suburbStateLoading, getAddOnTests, isIgnore]);

  if (suburbStateError) {
    console.error("Error", suburbStateError);
  }

  const handleClickAddOnTest = () => {
    setOpenAddOnTestDialog(true);
    console.log(ready);
  };

  const handleAddOnTestClose = () => {
    setOpenAddOnTestDialog(false);

    if (!props.isDesktop || mobility) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  };

  const handleEorderDialogClose = () => {
    setOpenEorderDialog(false);
  };

  const handleClickEorderLink = () => {
    setOpenAddOnTestDialog(false);
    setOpenEorderDialog(true);
  };

  return (
    <>
      <Box
        p={"16px"}
        className={`${styles.episodeCardHeader} ${styles.episodeCardHeaderNormal} ${
          props.active ? styles.episodeCardHeaderActive : ""
        }`}
        onClick={(e) => {
          if (props.onHeaderClick) {
            props.onHeaderClick(e);
          }
        }}
      >
        <Box display={"inline"}>
          <Typography className={`${props.active ? styles.mainTextActive : styles.mainText}`} variant={"h4"}>
            {" "}
            {sortedDate}
          </Typography>
          <Typography className={`${props.active ? styles.subTextActive : styles.subText}`} variant={"body2"}>
            {`Lab No. ${props.episode.labnoDigitOnly}`}
          </Typography>
          {props.episode && props.episode.panels[0].urno && props.episode.panels[0].urno !== "" ? (
            <Typography className={`${props.active ? styles.subTextActive : styles.subText}`} variant={"body2"}>
              {`UR No. ${props.episode && props.episode.panels[0].urno ? props.episode.panels[0].urno : ""}`}
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
          {!addOnTestLoading && !addOnTestError && addOnTests && addOnTests.length > 0 && (
            <Box display={"inline"} mr={4}>
              <PlusMenuIcon
                onClick={(e: any) => {
                  handleClickAddOnTest();
                  e.cancelBubble = true;
                  if (e.stopPropagation) e.stopPropagation();
                }}
              />
            </Box>
          )}
          {addOnTestLoading && (
            <Box display={"inline"}>
              <CircularProgress color="secondary" size={24} />
            </Box>
          )}
          <Box display={"inline"}>
            {reported.length > 0 && (
              <StatusCounter text={"Reports"} counter={reported.length} pending={false} mb={1} active={props.active} />
            )}
            {pending.length > 0 && (
              <StatusCounter text={"Pending"} counter={pending.length} pending={true} mb={1} active={props.active} />
            )}
            {props.showRemovedPanels && deleted.length > 0 && (
              <StatusCounter text={"Removed"} counter={deleted.length} pending={true} active={props.active} />
            )}
            {props.isDesktop && eOrderFeature && showGenEorderButton && validProvider && (
              <EorderDialog
                open={openEorderDialog}
                fullscreen={false}
                onClose={handleEorderDialogClose}
                patient={props.patient}
                reportingProviderNo={props.episode.panels[0].reportingProviderNo as string}
                setReady={setReady}
              />
            )}
          </Box>
        </Box>
      </Box>
      {!addOnTestLoading && !addOnTestError && addOnTests && addOnTests.length > 0 && (
        <AddOnTestDialog
          open={openAddOnTestDialog}
          fullscreen={fullscreen}
          sampleAge={reported.length > 0 ? reported[0].dateCollected : pending[0].dateCollected}
          ageInHours={ageInHours}
          patient={props.patient}
          reports={reported.length > 0 ? reported : pending}
          testNames={addOnTests}
          currentClock={currentClock}
          sendState={sendState}
          testRules={addOnTestRules}
          onClose={handleAddOnTestClose}
          onClick={handleClickEorderLink}
          linkToForm={eOrderFeature && showGenEorderButton && validProvider}
        />
      )}

      {openEorderDialog && (
        <EorderDialog
          open={openEorderDialog}
          fullscreen={false}
          onClose={handleEorderDialogClose}
          patient={props.patient as GetPatient_patient}
          reportingProviderNo={props.episode.panels[0].reportingProviderNo as string}
          setReady={setReady}
        />
      )}
    </>
  );
};

export default PatientEpisodeHeader;
