import { gql } from "@apollo/client";
export default gql`
  mutation SendReportViaEmail(
    $reportIds: [Int!]
    $patientId: Int
    $labnoDigitOnly: String
    $emailAddress: String
    $message: String
    $templateOption: String
  ) {
    sendReportViaEmail(
      reportIds: $reportIds
      patientId: $patientId
      labnoDigitOnly: $labnoDigitOnly
      emailAddress: $emailAddress
      message: $message
      templateOption: $templateOption
    ) {
      suceess
    }
  }
`;
