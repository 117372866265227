import React, { Fragment, useContext, useState } from "react";
import get from "lodash/get";
import styles from "./LoginPage.module.css";
import { withRouter, RouteComponentProps } from "react-router";
import { useMutation, MutationTuple, useApolloClient, LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import loginMutation from "./loginMutation";
import loginDelegateMutation from "./loginDelegateMutation";
import { Login, LoginVariables } from "./types/Login";
import { LoginDelegate, LoginDelegateVariables } from "./types/LoginDelegate";
import authService, { SET_AUTH } from "../../services/authService";
import { Link as RouterLink } from "react-router-dom";
import { Box, TextField, Link, Button, Typography, CircularProgress, Grid, Checkbox, createTheme } from "@mui/material";
import { ApolloError, ApolloClient } from "@apollo/client";
import { Formik } from "formik";
import MainNavigation from "../MainNavigation/MainNavigation";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import Announcement from "../Announcement/Announcement";
import { getQueryAnnouncement } from "../Announcement/types/getQueryAnnouncement";
import getAnnouncements from "../Announcement/getAnnouncement";
import { useQuery } from "@apollo/client";
import AppContext from "../../context/AppContext";
import { convertError } from "../ChangePasswordPage/PasswordValidation";
import advancedSearch from "../../model/advanced-search";
import queryString from "query-string";
import { animateScroll } from "react-scroll";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loading from "../Loading/Loading";
import { GetOptions } from "../App/types/GetOptions";
import { useLazyQuery } from "@apollo/client";
import GetOptionsQuery from "../App/GetOptionsQuery";
import { UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables } from "./types/UpdateUserDeviceSettings";
import UpdateUserDeviceSettingsMutation from "./UpdateUserDeviceSettingsMutation";
import { isTenantVet } from "../../services/tenantService";
import { storeMobileDeviceToken } from "./storeMobileDeviceToken";
import { getCpdProviders } from "./types/getCpdProviders";
import GetCpdProvidersQuery from "./GetCpdProvidersQuery";
import ACLLogo from "../../assets/CL logo.svg";
import SouthernSunLogo from "../../assets/SouthernSun Logo CMYK-GreyTag.svg";
import SpecialisedTrialsBlueLogo from "../../assets/Specialised Trials Blue CMYK Small.svg";
import gribblesVetLogo from "../../assets/gribbles-vet.svg";
import { makeStyles } from "@mui/styles";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  image: {
    maxWidth: "200px",
    height: "auto",
    alignSelf: "center",
  },
  clnicalLabImage: {
    maxWidth: "200px",
    height: "auto",
    alignSelf: "center",
    marginTop: "24px",
  },
  vetImage: {
    maxWidth: "200px",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  globalGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    },
  },
}));

type LoginFormErrors = {
  username?: boolean;
  password?: boolean;
};

type LoginFormValues = {
  username: string;
  password: string;
};

type LoginBoxProps = {
  isLoading: boolean;
  onSubmit: (username: string, password: string) => Promise<void>;
  isDelegateLoading?: boolean;
  error?: ApolloError;
  isAnnouncementAvai?: boolean;
  announcedata?: getQueryAnnouncement;
};

let rememberUsername = false;
let checkBoxChecked = false;

export const LoginBox: React.FC<LoginBoxProps> = (props) => {
  const classes = useStyles();
  const { error, isLoading } = props;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const initialValues: LoginFormValues = {
    username: authService.getRememberMe(),
    password: "",
  };

  const isDesktop = useContext(AppContext).isDesktop;

  if (authService.getRememberMe() !== "") {
    checkBoxChecked = true;
  } else {
    checkBoxChecked = false;
  }

  const [remChecked, setRemChecked] = React.useState(checkBoxChecked);

  if (remChecked) {
    rememberUsername = true;
  }

  const validate = (values: LoginFormValues) => {
    const errors: LoginFormErrors = {};
    if (!values.username) {
      errors.username = true;
    }
    if (!values.password) {
      errors.password = true;
    }
    return errors;
  };

  const submit = (values: LoginFormValues) => {
    authService.clearAll();
    props.onSubmit(values.username.trim(), values.password);
  };

  const selectRemember = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      rememberUsername = true;
    } else {
      rememberUsername = false;
    }
    setRemChecked(event.target.checked);
  };

  function scrollTop() {
    animateScroll.scrollToTop();
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const receiveMessage = (event: any): void => {
    if (event.data) {
      const eDataString = event.data + "";
      let eData: string[] = [];

      if (eDataString.includes("\t")) {
        eData = eDataString.split("\t");
      } else {
        eData = eDataString.split("~");
      }

      if (eData[0] === "eResultsMobileLogin") {
        props.onSubmit(eData[1], eData[2]);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  }, []);

  return (
    <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
      <Grid item xs={12} md={props.isAnnouncementAvai ? 6 : 12}>
        <AuthLayout title="Sign In" maxWidth={"490px"} isAnnouncementAvai={props.isAnnouncementAvai}>
          <Box mt={2}>
            {error &&
              error.graphQLErrors.map(({ message }, i) =>
                message === "IP Address Not Allowed" ? (
                  <Typography key={i} color="error">
                    {
                      "Your login was unsuccessful because you are attempting to log in from a network that is not approved by your account manager. If you believe this is incorrect, please contact"
                    }
                    <Link
                      href="mailto:ehealth.helpdesk@clinicallabs.com.au?subject=eResults%20Access%20Error"
                      variant="body1"
                      color="primary"
                      align="center"
                    >
                      <span style={{ margin: "0px 5px" }}>technical support</span>
                    </Link>
                    {"immediately to have your account rectified."}
                  </Typography>
                ) : (
                  <Typography
                    key={i}
                    color="error"
                    dangerouslySetInnerHTML={{ __html: convertError(message) }}
                  ></Typography>
                ),
              )}
          </Box>
          {props.isDelegateLoading ? (
            <Box mt={2} className={styles.footer}>
              <Typography>Authenticating single use token... Please wait</Typography>
              <Loading></Loading>
            </Box>
          ) : (
            <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
              {({ errors, handleChange, handleBlur, handleSubmit, touched }) => (
                <form onSubmit={handleSubmit}>
                  <Box mt={4}>
                    <TextField
                      id="username"
                      name="username"
                      label="Username/Email"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      error={!!errors.username && touched.username}
                      onBlur={handleBlur}
                      defaultValue={initialValues["username"]}
                    ></TextField>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      id="password"
                      name="password"
                      fullWidth
                      label="Password"
                      type={values.showPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.password && touched.password}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    ></TextField>
                  </Box>

                  <Box mt={1}>
                    <Checkbox
                      name="Remember"
                      color="primary"
                      disabled={false}
                      onChange={selectRemember}
                      checked={remChecked}
                    />
                    Remember my username/email
                  </Box>

                  <Box mt={3}>
                    <Link color="primary" component={RouterLink} to="/forgot">
                      Forgot password?
                    </Link>
                  </Box>

                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box flexGrow={1}>
                      {!isTenantVet() && (
                        <Link
                          color="primary"
                          href="https://www.clinicallabs.com.au/register/ehealth/"
                          target="_blank"
                          rel="noopener"
                        >
                          Create account
                        </Link>
                      )}
                      {!authService.isMobility() && isTenantVet() && (
                        <Link
                          color="primary"
                          href="https://eresults.gribbles.com.au/ios/"
                          target="_blank"
                          rel="noopener"
                        >
                          Create account
                        </Link>
                      )}
                    </Box>

                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoading}
                      className={styles.loginButton}
                      onClick={scrollTop}
                    >
                      {isLoading ? (
                        <Box width={"100%"} textAlign="center">
                          <CircularProgress size={16} />
                        </Box>
                      ) : (
                        <span>Login</span>
                      )}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </AuthLayout>
      </Grid>
      {props.isAnnouncementAvai && props.announcedata && (
        <Grid item xs={12} md={6}>
          <Announcement data={props.announcedata} />
        </Grid>
      )}
      {!isTenantVet() && (
        <Grid container display="flex" className={classes.globalGrid} rowSpacing={4} style={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={12} md={3} className={classes.globalGrid}>
            <img src={ACLLogo} className={classes.clnicalLabImage} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.globalGrid}>
            <img src={SpecialisedTrialsBlueLogo} className={classes.clnicalLabImage} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.globalGrid}>
            <img src={SouthernSunLogo} className={classes.image} />
          </Grid>
        </Grid>
      )}
      {isTenantVet() && (
        <Grid container spacing={2} display="flex" alignItems="center" justifyContent="center" mt={1}>
          <Grid item xs={12} sm={6} md={3}>
            <img src={gribblesVetLogo} className={classes.vetImage} />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box mt={4} className={styles.footer}>
          <Typography variant="body2" color="textSecondary">
            By logging in you agree to the{" "}
            <Link
              className={styles.terms}
              color="textSecondary"
              href="https://ehealth.clinicallabs.com.au/terms_conditions.php"
              target="_blank"
              rel="noopener"
            >
              Terms &amp; Conditions
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

// TODO: shouldn't manually set route on successful login to /patients,
// ProtectedRoute saves the current route in location state which
// should be restored here and redirected to.

const onSubmit =
  (
    location: RouteComponentProps["location"],
    history: RouteComponentProps["history"],
    login: MutationTuple<Login, LoginVariables>[0],
    client: ApolloClient<any>,
    siteOptions: LazyQueryExecFunction<GetOptions, Record<string, never>>,
    updateUsersDeviceConfig: MutationTuple<UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables>[0],
    cpdProviders: LazyQueryExecFunction<getCpdProviders, OperationVariables>,
  ) =>
  async (username: string, password: string) => {
    // TODO: persist token to local storage
    const useragent = navigator.userAgent;
    const mobility = authService.isMobility();
    const setCookie = true;
    const trustBrowser = authService.getCookieValue(`trustBrowser_${username.toUpperCase()}`);
    let deviceId = "";

    try {
      if (authService.isMobility()) {
        const devicevalue = authService.getMobileDeviceId();
        const device = devicevalue ? devicevalue.split("|") : [];

        if (device.length >= 4) {
          deviceId = device[0];
        }
      }

      const result = await login({
        variables: {
          input: {
            username,
            password,
            useragent,
            mobility,
            setCookie,
            trustBrowser,
            deviceId,
          },
        },
      });

      if (result.data) {
        authService.clearSearchText(); // http://jira.pl.pathlabs.com.au/browse/ER8-97
        authService.persistAllValues(result.data.login);
        authService.clearDelegatedUser(); //Clear delegated user in any case if we logged in via normal login.

        if (mobility) {
          localStorage.setItem("x-object", btoa(password));
        }

        if (rememberUsername) {
          authService.setRememberMe(username);
        } else {
          authService.clearRememberMe();
        }

        client.cache.writeQuery({
          query: SET_AUTH,
          data: { auth: { isAuth: true, __typename: "Auth" } },
        });

        const isCtFrom = authService.isClinicalTrial() && !mobility ? "/patient-episodes" : "/patients";
        let from: any = get(location, "state.from") || isCtFrom;

        // Get site options
        await siteOptions({}).then((v) => {
          if (v.data) {
            authService.setSiteOptions(v.data.getOptions);
          }
        });
        // if (options.data) {
        //   authService.setSiteOptions(options.data.getOptions);
        // }

        //For the default ward search we are including advancedSearch query string here
        if (authService.getDefaultWardCode()) {
          const wards = authService.getWards();

          const AdSearch: advancedSearch = new advancedSearch();
          AdSearch.wardCode = wards.find((w) => w.code === authService.getDefaultWardCode());
          const newquerystring = `?${AdSearch.toQueryString()}`;
          // eslint-disable-next-line no-const-assign
          from = get(location, "state.from") || `/patients${newquerystring}`;
        }

        let otpRequired = false;
        if (
          authService.getMFAenrolled() == true &&
          authService.getOtpVerfiedEnrolled() == false &&
          authService.getForceAuth() == true
        ) {
          authService.setLastViewed(typeof from !== "string" && from.pathname ? from.pathname : from);
          from = "/mfaauthentication";
          otpRequired = true;
        } else if (authService.getForceEnrol()) {
          //Goes back to /enrolment
          from = "/enrolment";
          otpRequired = true;
        }

        if (!otpRequired) {
          await storeMobileDeviceToken(updateUsersDeviceConfig, cpdProviders);
        }

        await client.resetStore();
        history.replace(from);
      }
    } catch (e) {
      // don't do anything - the error will be displayed on screen
      console.error(e);
    }
  };

const LoginPage: React.FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const [login, { loading, error }] = useMutation<Login, LoginVariables>(loginMutation);
  const [loginDelegate, { loading: loadingDelegate, error: errorDelegate }] = useMutation<
    LoginDelegate,
    LoginDelegateVariables
  >(loginDelegateMutation);
  const [siteOptions, { loading: optionsLoading }] = useLazyQuery<GetOptions, Record<string, never>>(GetOptionsQuery);
  const [updateUsersDeviceConfig] = useMutation<UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables>(
    UpdateUserDeviceSettingsMutation,
  );
  const [cpdProviders] = useLazyQuery<getCpdProviders, OperationVariables>(GetCpdProvidersQuery);

  const qs = queryString.parse(location.search);
  const mobility = qs.ref === "mobility";
  if (mobility) {
    authService.setMobility(mobility);
  }

  const { data } = useQuery<getQueryAnnouncement>(getAnnouncements, {});
  const [isAnnouncementAvai, setIsAnnouncementAvai] = useState(false);
  useEffect(() => {
    if (data) {
      if (data.announcement.announce.length > 0) {
        setIsAnnouncementAvai(true);

        if (mobility) {
          const e = document.createElement("base");
          e.target = "_blank";
          document.head.appendChild(e);
        }
      }
    } else {
      setIsAnnouncementAvai(false);
    }
  }, [data, mobility]);

  const [runOnce, setRunOnce] = useState(false);

  const client: ApolloClient<any> = useApolloClient();
  if (error) {
    console.error("Error", error);
  }

  const singleUseToken = qs.singleusetoken || "";

  if (singleUseToken && typeof singleUseToken === "string" && !runOnce) {
    //   //Should logged in directly here.
    setRunOnce(true);

    const useragent = navigator.userAgent;
    const setCookie = true;

    try {
      loginDelegate({
        variables: {
          input: {
            singleUseToken,
            useragent,
            mobility,
            setCookie,
          },
        },
      })
        .then(async (result) => {
          if (!result.data) {
            return;
          }

          authService.clearSearchText(); // http://jira.pl.pathlabs.com.au/browse/ER8-97
          authService.persistAllValues(result.data.loginDelegate);
          authService.setDelegatedUser(true);

          //always set to non-expired if login via delegate.
          authService.clearExpired();

          client.cache.writeQuery({
            query: SET_AUTH,
            data: { auth: { isAuth: true, __typename: "Auth" } },
          });

          const isCtFrom = authService.isClinicalTrial() && !mobility ? "/patient-episodes" : "/patients";
          let from = get(location, "state.from") || isCtFrom;

          // Get site options
          const options = await siteOptions({});
          if (options.data) {
            authService.setSiteOptions(options.data.getOptions);
          }

          //For the default ward search we are including advancedSearch query string here
          if (authService.getDefaultWardCode()) {
            const wards = authService.getWards();

            const AdSearch: advancedSearch = new advancedSearch();
            AdSearch.wardCode = wards.find((w) => w.code === authService.getDefaultWardCode());
            const newquerystring = `?${AdSearch.toQueryString()}`;
            // eslint-disable-next-line no-const-assign
            from = get(location, "state.from") || `/patients${newquerystring}`;
          }

          await cpdProviders().then((v) => {
            if (v.data) {
              authService.setCpdProviders(v.data.getCpdProviders);
            }
          });

          // these history commands are required to make sure the "Back to patient list" option works
          // when "Open New tab" option is not enabled
          history.replace(from);
          document.location.reload();
        })
        .catch(() => {
          // don't do anything - the error will be displayed on screen
        });
    } catch {
      // don't do anything - the error will be displayed on screen
    }
  }

  // TODO: should use useCallback for onSubmit cb
  return (
    <Fragment>
      <MainNavigation hideTopMenu isAnnouncementAvai={isAnnouncementAvai} />
      <LoginBox
        isDelegateLoading={loadingDelegate}
        isLoading={loading || optionsLoading}
        onSubmit={onSubmit(location, history, login, client, siteOptions, updateUsersDeviceConfig, cpdProviders)}
        error={error || errorDelegate}
        isAnnouncementAvai={isAnnouncementAvai}
        announcedata={data}
      />
    </Fragment>
  );
};

export default withRouter(LoginPage);
