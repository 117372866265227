import { gql } from "@apollo/client";

export default gql`
  query GetSignalResistanceCSV($filename: String!) {
    getSignalResistanceCSVData(filename: $filename) {
      code
      error
      message
      data {
        id
        ic_org_name
        ic_org
        service_date
        labreq
        panel
        sp_site
      }
    }
  }
`;
