/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useDialogStyles } from "../DialogBox/styles/dialogStyles";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";

type PopUpCardProps = {
  open: boolean;
  onSetOpenOda: (st: boolean) => void;
  onClose: () => void;
  previousReports?: any;
  removedReports?: any;
  onContinue: () => void;
};

const useStyles = useDialogStyles;

const PopUpCard: React.FC<PopUpCardProps> = (props) => {
  const classes = useStyles();
  const handleUserContinue = (selectedOption: boolean) => {
    props.onSetOpenOda(false);
    if (selectedOption) {
      props.onContinue();
    } else {
      props.onClose();
      props.onSetOpenOda(false);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle title="Disclaimer" width={200} closeButton handleClose={props.onClose} />
        </DialogTitle>
        <DialogContent>
          <Container className={classes.shareContainer}>
            <DialogContentText id="alert-dialog-description">
              {props.previousReports.length === props.removedReports.length
                ? "Reports for this patient cannot be shared as they were accessed via the Open Doctor Access (ODA)"
                : "Some reports for this patient were removed from sharing because they were accessed via the Open Doctor Access (ODA). Do you wish to continue with the remaining reports? "}
            </DialogContentText>
          </Container>
        </DialogContent>
        <DialogActions>
          {props.previousReports.length === props.removedReports.length ? (
            <Button variant="contained" color="primary" onClick={() => handleUserContinue(false)}>
              Close
            </Button>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={() => handleUserContinue(true)}>
                Continue
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleUserContinue(false)}>
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopUpCard;
