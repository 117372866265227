import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import authService from "../services/authService";
import { CPD_PROGRAMS } from "./CPDprograms/types";
// import ChangeEmailPage from "./ChangeEmailPage/ChangeEmailPage";
import ChangePasswordPage from "./ChangePasswordPage/ChangePasswordPage";
import ContactSupportPage from "./ForgotPasswordPage/ContactSupportPage";
import ForgotSharedAccountPage from "./ForgotPasswordPage/ForgotSharedAccountPage";
import MFAAuthenticationPage from "./MFAAuthentication/MFAAuthenticationPage";
import MFAEnrolmentPage from "./MFAEnrolment/MFAEnrolmentPage";
import StudyPage from "./study/StudyPage";
import PatientListPage from "./PatientListPage/PatientListPage";
import EpisodeListPage from "./PatientEpisodesPage/EpisodeListPage";
import CPDprogramsPage from "./CPDprograms/CPDprogramsPage";
import CPDcytologyPage from "./CPDprograms/CPDcytologyPage";
import CPDdiabetePages from "./CPDprograms/CPDdiabetesPage";
import CPDskinPage from "./CPDprograms/CPDskinPage";
import { isTenantVet } from "../services/tenantService";
import DeleteAccountPage from "./DeleteAccountPage/DeleteAccountPage";

type ProtectedRouteProps = RouteProps & {
  component: React.ComponentType;
  isAuth: boolean;
  title?: string;
  isCognito: boolean;
};

class redirectValidator {
  public component: React.ComponentType;

  constructor(chckingComponent: React.ComponentType) {
    this.component = chckingComponent;
  }

  public checkingList = [
    this.checkIsExpired,
    this.checkIsLogin,
    this.checkIsBypassVerify,
    this.checkIsVerified,
    this.checkForceEnrol,
    this.checkIfClinicalTrials,
    this.checkStudyPageAccess,
    this.checkEpisodeListPageAccess,
    this.checkCPDprogramAccess,
    this.checkDeleteAccountPageAccess,
  ];
  public autoPlay() {
    //auto play all validations.
    for (let i = 0; i < this.checkingList.length; i++) {
      const resFun = this.checkingList[i].bind(this);
      const res = resFun();
      if (res) {
        return res;
      }
    }
    return "";
  }
  public checkIsLogin() {
    //if user is not login, rediret to login page'
    if (!authService.isAuthenticated()) {
      return "/login";
    }
  }

  public checkIsBypassVerify() {
    //if user enalbe enrolment and bypass the verification
    if (
      authService.getMFAenrolled() == true &&
      authService.getOtpVerfiedEnrolled() == false &&
      authService.getForceAuth() == true &&
      this.component !== MFAAuthenticationPage
    ) {
      return "/mfaauthentication";
    }
  }

  public checkIsVerified() {
    //check is has otp verfied and try to acess mfaauth or enrol page, if verfied redirect to patient page.
    if (
      authService.getOtpVerfiedEnrolled() == true &&
      (this.component === MFAAuthenticationPage || this.component === MFAEnrolmentPage)
    ) {
      if (authService.isClinicalTrial() && !authService.isMobility()) {
        return "/patient-episodes";
      } else {
        return "/patients";
      }
    }
  }

  public checkForceEnrol() {
    //if user force enrol, redirect to enrol page.
    if (authService.getForceEnrol() && !authService.getDelegated() && this.component !== MFAEnrolmentPage) {
      return "/enrolment";
    }
  }

  public checkIsExpired() {
    //user password expired, redite to change password page
    if (
      authService.getExpired() &&
      authService.getOtpVerfiedEnrolled() == true &&
      this.component !== ChangePasswordPage &&
      this.component !== MFAAuthenticationPage
    ) {
      return "/change-password";
    }
  }

  public checkIfClinicalTrials() {
    // Clinical Trials users have their own Landing Page
    if (authService.isClinicalTrial() && !authService.isMobility() && this.component === PatientListPage) {
      return "/patient-episodes";
    }
  }

  public checkStudyPageAccess() {
    if ((!authService.isHospitalAdmin() || !authService.isClinicalTrial()) && this.component === StudyPage) {
      return "/patients";
    }
  }

  public checkEpisodeListPageAccess() {
    if (this.component === EpisodeListPage && (!authService.isClinicalTrial() || authService.isMobility())) {
      return "/patients";
    }
  }

  public checkCPDprogramAccess() {
    const cpdEnrolments: Array<number> = authService.getCPDEnrolled();
    let defaultReturnPage = "/patients";

    ////if not enrol any CPD but has valid providers, still able to access enrol page.
    if (cpdEnrolments.length > 0 || authService.getCpdProviders()?.length) {
      defaultReturnPage = "./cpd-programs";
    }
    switch (this.component) {
      case CPDcytologyPage:
        if (isTenantVet() || !cpdEnrolments.includes(CPD_PROGRAMS.CYTOLOGY_EVALUATION_PROGRAM)) {
          return defaultReturnPage;
        }
        break;
      case CPDdiabetePages:
        if (isTenantVet() || !cpdEnrolments.includes(CPD_PROGRAMS.DIABETES_CLINICAL_EVALUATION_PROGRAM)) {
          return defaultReturnPage;
        }
        break;
      case CPDskinPage:
        if (isTenantVet() || !cpdEnrolments.includes(CPD_PROGRAMS.SKIN_PATHOLOGY_EVALUATION_PROGRAM)) {
          return defaultReturnPage;
        }
        break;
      case CPDprogramsPage:
        if (isTenantVet() || !authService.getCpdProviders()?.length) {
          return "/patients";
        }
    }
  }

  public checkDeleteAccountPageAccess() {
    if (this.component === DeleteAccountPage && authService.getDelegatedUser()) {
      return "/account-settings";
    }
  }
}

export const redirectPath = (component: React.ComponentType): string => {
  const validator = new redirectValidator(component);
  const redirectUrl = validator.autoPlay();
  if (redirectUrl) {
    return redirectUrl as string;
  }
  return "";
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  if (!rest.isAuth)
    return (
      <Redirect
        to={{
          pathname: rest.isCognito ? "/login-sso" : "/login",
          state: { from: rest.location },
        }}
      />
    );

  if (rest.title) {
    document.title = rest.title;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const path = redirectPath(Component);
        //remove biometrics parameter if that's from goBack method.
        const queryParams = new URLSearchParams(props.location.search);
        if (queryParams.has("biometrics")) {
          queryParams.delete("biometrics");
          props.history.replace({
            search: queryParams.toString(),
          });
        }

        return path ? (
          <Redirect
            to={{
              pathname: path,
              state: { from: props.history.location },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

type PrivateRouteProps = RouteProps & {
  isAuth: boolean;
  title?: string;
  isCognito: boolean;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  if (!rest.isAuth)
    return (
      <Redirect
        to={{
          pathname: rest.isCognito ? "/login-sso" : "/login",
          state: { from: rest.location },
        }}
      />
    );

  if (rest.title) {
    document.title = rest.title;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authService.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: rest.isCognito ? "/login-sso" : "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const SwitchingRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (Component === ContactSupportPage) {
          return authService.getSharedAccount() ? <ForgotSharedAccountPage /> : <Component />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
