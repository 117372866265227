import React, { useContext } from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Box, Typography, FormHelperText } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { SendReportViaEmailVariables, SendReportViaEmail } from "../PanelReports/types/SendReportViaEmail";
import shareEmailReportMutation from "../PanelReports/PatientReportEmailMutation";
import { useMutation } from "@apollo/client";
import { useDialogStyles } from "./styles/dialogStyles";
import AppContext from "../../context/AppContext";
import { eResultsOptions, getSiteOption } from "../../utils/siteOptions";
import styles from "../EorderDialog/EorderDialog.module.css";

interface DialogBoxShareEmailProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  reportsToSende: {
    selected?: Record<string, boolean>;
    patient?: number;
    labno?: string;
  };
}

const useStyles = useDialogStyles;

const DialogBoxShareEmail: React.FC<DialogBoxShareEmailProps> = (props) => {
  const classes = useStyles();
  const [sendEmail, { loading }] = useMutation<SendReportViaEmail, SendReportViaEmailVariables>(
    shareEmailReportMutation,
  );
  const disclaimerOption = (getSiteOption(eResultsOptions.SHARE_TO_FEATURE) as any)?.value?.disclaimer || "";
  const { setSuccess } = useContext(AppContext);
  const [page, setPage] = React.useState(1);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const isValidEmail = (email: string) => {
    if (email.trim() === "") {
      return false;
    }
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onClose = () => {
    setPage(1);
    props.onClose();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBoxBig }}
        className={styles.sizeDialogDesltop}
      >
        <DialogTitle className={styles.dialogTitleWithMargin}>
          <DialogBoxTitle
            title={page === 1 ? "Complete Details" : "Confirm & Send"}
            width={450}
            closeButton
            handleClose={onClose}
            marginLeft={-3}
            closeButtonNoRightPadding
          />
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          {page === 1 ? (
            <>
              <Box m={2}>
                <Typography>Enter the email address you would like to send results to:</Typography>
              </Box>
              <Box m={2}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  value={email}
                  error={!isValidEmail(email)}
                />
                {!isValidEmail(email) ? (
                  <FormHelperText id="component-error-text" error>
                    {email.trim() === "" ? "Email is required" : "Invalid Email"}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
              <Box m={2}>
                <TextField
                  margin="dense"
                  id="message"
                  label="Message (Optional)"
                  name="message"
                  type="text"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setMessage(event.target.value);
                  }}
                  multiline
                  minRows={4}
                  value={message}
                />
              </Box>
            </>
          ) : (
            <>
              <Box m={2}>
                <Typography>
                  By clicking ‘Send’ you intend to email the selected results to the email address below.
                </Typography>
              </Box>
              <Box m={2}>
                <Typography>Email: {email}</Typography>
              </Box>
              <Box m={2}>
                <Typography color="textSecondary">
                  {" "}
                  {disclaimerOption !== "" ? `Disclaimer: ${disclaimerOption}` : ""}
                </Typography>
              </Box>
            </>
          )}
          <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
            <Button
              onClick={() => {
                if (page === 1) {
                  props.onBack();
                } else {
                  setPage(page - 1);
                }
              }}
              variant="contained"
              color="secondary"
            >
              Back
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                if (page === 2) {
                  sendEmail({
                    variables: {
                      emailAddress: email,
                      labnoDigitOnly: props.reportsToSende.labno,
                      patientId: parseInt(String(props.reportsToSende.patient || "0")) || 0,
                      reportIds: Object.keys(props.reportsToSende.selected ?? {}).map((key) => parseInt(key)),
                      message: message,
                      templateOption: "shareReport",
                    },
                  }).then(() => {
                    setSuccess(`Results have been shared to ${email}`);
                    onClose();
                  });
                } else {
                  if (isValidEmail(email)) {
                    setPage(page + 1);
                  } else {
                    // Show email validation error
                  }
                }
              }}
              variant="contained"
              color="primary"
            >
              {page === 1 ? "Next" : "Send"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogBoxShareEmail;
