import authService from "../services/authService";

export enum eResultsOptions {
  ADDON_IGNORE = "ADDON_IGNORE",
  EORDER_BLACKLIST = "EORDER_BLACKLIST",
  EORDER_FEATURE = "EORDER_FEATURE",
  EORDER_UPLOAD_CONSENT = "EORDER_UPLOAD_CONSENT",
  EORDER_USE_IFRAME = "EORDER_USE_IFRAME",
  TRUST_BROWSER_PERIOD = "TRUST_BROWSER_PERIOD",
  SHARE_TO_SMSC = "SHARE_TO_SMSC",
  SHARE_TO_FEATURE = "SHARE_TO_FEATURE",
  SHARE_SMSC_EDI_LINKS = "SHARE_SMSC_EDI_LINKS",
}

type JsonOptions = {
  value: any;
};

export const getSiteOption = (paramName: string): string[] | number | string | JsonOptions => {
  let result: string[] | number | string | JsonOptions = "";
  const siteOptions = authService.getSiteOptions();
  const siteOption = siteOptions.filter((s) => s.paramname === paramName);

  if (siteOption.length > 0) {
    const optionType = siteOption[0].type;

    if (optionType === "array") {
      result = siteOption[0].value.split(",");
    } else if (optionType === "json") {
      const value: JsonOptions = {
        value: JSON.parse(siteOption[0].value),
      };
      result = value;
    } else if (optionType === "number") {
      result = siteOption[0].value as unknown as number;
    } else {
      result = siteOption[0].value;
    }
  }

  return result;
};

export const singleProvider = (): string => {
  let result: string = authService.getProviderSelected();

  if (result === "0" && authService.getProviders().length === 1) {
    result = authService.getProviders()[0];
  }

  return result;
};

export const showEorderButton = (blacklist: string[], provider: string): boolean => {
  let result = true;

  // check is selected provider is part of the blacklist
  if (provider !== "0" && blacklist.includes(provider)) {
    result = false;
  }

  return result;
};
