import React, { useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Card, CardContent, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MainNavigation } from "../MainNavigation/MainNavigation";
import AppBar from "@mui/material/AppBar";
import CpdStyledHeader from "./CpdStyledHeader";
import styles from "./CPDprograms.module.css";
import theme from "../theme";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { ApolloClient, useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import getSkinAuditQuery from "./GetSkinAuditQuery";
import getSkinReportUrlQuery from "./GetSkinReportPdfQuery";
import { getSkinAuditReport, getSkinAuditReport_getSkinAuditReport_rows } from "./types/getSkinAuditReport";
import { getSkinReportLink } from "./types/getSkinReportLink";
import authService from "../../services/authService";
import Grid from "@mui/material/Grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import thm from "../theme";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Tabs, Tab, TabProps } from "@mui/material";
import { Link } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import printJS from "print-js";
import { omit } from "lodash";
import GetReportCountQuery from "./GetReportCountQuery";
import { getCpdReportCount } from "./types/getCpdReportCount";
import { CPD_PROGRAMS } from "./types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AppContext from "../../context/AppContext";
import { downloadHelper } from "../../utils/downloadHelper";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export type CPDskinProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  downloadcsv: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
  },
  clearButton: {
    cursor: "pointer",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontWeight: "bold",
  },
  welcomeLogo: {
    width: "400px",
    height: "400px",
  },
  container: {
    width: "400px",
    height: "400px",
    position: "relative",
  },
  shape: {
    borderStyle: "solid",
    msTransform: "rotate(360deg)",
    oTransform: "rotate(360deg)",
    webkitTransform: "rotate(360deg)",
    transform: "rotate(360deg)",
    borderColor: "transparent " + theme.colorSchemes.light.palette.primary.dark + " transparent transparent",
    float: "right",
    right: "0px",
    height: "0px",
    width: "0px",
    borderWidth: "0 120px 120px 0",
    position: "absolute",
    display: "flex",
  },
  shapeText: {
    transform: "rotate(45deg)",
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "bold",
    position: "relative",
    right: "-60px",
    top: "5px",
    textAlign: "center",
    margin: "auto",
  },
  resetButtonMob: {
    backgroundColor: "#979797 !important",
    marginLeft: "2px",
    color: "#ffffff",
  },
  clearIcon: {
    opacity: "0",
  },
  input: {
    height: "46px",
  },
  reportIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

const CPDskinPage: React.FC<CPDskinProps> = () => {
  const classes = useStyles();
  const { isDesktop } = useContext(AppContext);
  const providers = authService.getCpdProviderValues();
  const skinReportStart = localStorage.getItem("skinReportStart");
  const skinReportEnd = localStorage.getItem("skinReportEnd");
  const [tableData, setTableData] = useState<getSkinAuditReport_getSkinAuditReport_rows[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);
  const [tableDataPage, setTableDataPage] = useState<number>(0);
  const [reportCount, setReportCount] = useState<number>(0);
  const [reportStartDate, setreportStartDate] = useState<string | number | Dayjs | Date | null | undefined>(
    skinReportStart ? dayjs(skinReportStart) : dayjs().subtract(1, "year").startOf("year"),
  );
  const [reportEndDate, setreportEndDate] = useState<string | number | Dayjs | Date | null | undefined>(
    skinReportEnd ? dayjs(skinReportEnd) : dayjs(),
  );
  const [sortModel, setSortModel] = useState<string[]>([]);
  const [getSkinAuditReportQuery, { loading, error: reportListError }] = useLazyQuery<getSkinAuditReport>(
    getSkinAuditQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data) {
          setTableData(data.getSkinAuditReport.rows as getSkinAuditReport_getSkinAuditReport_rows[]);
          setTableDataCount(data.getSkinAuditReport.rowCount);

          auditLog({
            variables: {
              audit: {
                value,
                action,
              },
            },
          });
        }
      },
    },
  );
  const mobility = authService.isMobility();
  const [pageSize, setPageSize] = useState<number>(50);
  const { data: reportCountData } = useQuery<getCpdReportCount>(GetReportCountQuery, {
    variables: {
      cpdProgram: CPD_PROGRAMS.SKIN_PATHOLOGY_EVALUATION_PROGRAM,
    },
    fetchPolicy: "no-cache",
  });
  const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const [getSkinAuditUrlQuery, { error: reportLinkError, variables: currVariables }] = useLazyQuery<getSkinReportLink>(
    getSkinReportUrlQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (data: { getSkinReportLink: any }) => {
        const id = currVariables?.id;

        if (data && data.getSkinReportLink) {
          const existingData = client.cache.readQuery<getSkinAuditReport>({
            query: getSkinAuditQuery,
            variables: {
              page: tableDataPage,
              providers: providers,
              sort: sortModel,
              reportStartDate: reportStartDate,
              reportEndDate: reportEndDate,
              pageSize: pageSize,
            },
          });

          client.cache.writeQuery<getSkinAuditReport>({
            query: getSkinAuditQuery,
            variables: {
              page: tableDataPage,
              providers: providers,
              sort: sortModel,
              reportStartDate: reportStartDate,
              reportEndDate: reportEndDate,
              pageSize: pageSize,
            },
            data: {
              getSkinAuditReport: {
                __typename: "CPDSkinAuditResult",
                rows:
                  existingData?.getSkinAuditReport.rows.map((report: any) => {
                    if (report.id === parseInt(id)) {
                      return {
                        ...report,
                        viewCount: report.viewCount + 1,
                        last_viewed: dayjs().format("DD MMM YYYY"),
                        first_viewed: report.first_viewed || dayjs().format("DD MMM YYYY"),
                      };
                    }
                    return report;
                  }) || [],
                rowCount: existingData?.getSkinAuditReport.rowCount || 0,
              },
            },
          });
        }
      },
    },
  );
  const client: ApolloClient<any> = useApolloClient();
  const [hideIframe, setHideIframe] = useState(true);
  const [pdfUrl, setPdfUrl] = useState("");
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const action = "CPD_SKIN_VIEW";
  const value = JSON.stringify({ index: 0, label: "View Skin Reports" });

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const tabPage = 0;

  useEffect(() => {
    setTableData([]);
    getSkinAuditReportQuery({
      variables: {
        page: tableDataPage,
        providers: providers,
        sort: sortModel,
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        pageSize: pageSize,
      },
    });
  }, [sortModel, tableDataPage, pageSize]);
  useEffect(() => {
    setReportCount(reportCountData?.getCpdReportCount.reportCount || 0);
  }, [reportCountData]);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length > 0) {
      setSortModel([sortModel[0].field, sortModel[0].sort || ""]);
    }
  }, []);

  const hoverIn = async (reportParams: any) => {
    setHideIframe(false);
    if (!reportParams.params.id) {
      return;
    }
    const res = await getSkinAuditUrlQuery({
      variables: {
        id: reportParams.params.id,
      },
    });
    if (res.data?.getSkinReportLink.url) {
      setPdfUrl(res.data?.getSkinReportLink.url);
    }
  };
  const printPdfHandler = async (event: React.MouseEvent, reportParams: any) => {
    setHideIframe(true);
    if (!reportParams.id) {
      return;
    }
    const res = await getSkinAuditUrlQuery({
      variables: {
        id: reportParams.id,
      },
    });
    if (res.data?.getSkinReportLink.url) {
      printJS({
        printable: res.data?.getSkinReportLink.url,
        type: "pdf",
        showModal: true,
        onIncompatibleBrowser: () => {
          console.log("NOT WORKING");
        },
      });
    }
  };

  useEffect(() => {
    if (tableData.length <= 0) {
      getSkinAuditReportQuery({
        variables: {
          page: tableDataPage,
          providers: providers,
          sort: sortModel,
          reportStartDate: reportStartDate,
          reportEndDate: reportEndDate,
          pageSize: pageSize,
        },
      });
    }
  }, []);

  const handleSearch = () => {
    setTableData([]);
    getSkinAuditReportQuery({
      variables: {
        page: tableDataPage,
        providers: providers,
        sort: sortModel,
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        pageSize: pageSize,
      },
    });
  };
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      if (reportEndDate && dayjs(date).isAfter(reportEndDate)) {
        setreportEndDate(date);
        setreportStartDate(date);
        return;
      }

      setreportStartDate(date);
      localStorage.setItem("skinReportStart", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setreportStartDate("");
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      if (reportStartDate && !dayjs(date).isAfter(reportStartDate)) {
        setreportStartDate(date);
        setreportEndDate(date);
        return;
      }

      setreportEndDate(date);
      localStorage.setItem("skinReportEnd", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setreportEndDate("");
    }
  };

  const handleOpenLinkClick = async (event: React.MouseEvent, params: any) => {
    const res = await getSkinAuditUrlQuery({
      variables: {
        id: params.id,
      },
    });
    if (res.data?.getSkinReportLink.url) {
      window.open(res.data?.getSkinReportLink.url, "_blank");
    }
  };
  const handleDownloadClick = async (event: React.MouseEvent, params: any) => {
    console.log(params);
    const id = params.id;
    const res = await getSkinAuditUrlQuery({
      variables: {
        id: id,
      },
    });
    if (res.data?.getSkinReportLink.url) {
      fetch(res.data?.getSkinReportLink.url)
        .then((res) => res.blob())
        .then((blob) => {
          const pdfFilename = params.row && params.row.name ? params.row.name : params.name;
          downloadHelper(blob, pdfFilename);
        });
      //presign url not able to use saveas, enabled cors on s3
      //https://github.com/eligrey/FileSaver.js/issues/483
    }
  };

  type LinkTabProps = TabProps<Link> & {
    isActive: boolean;
    label?: string;
    href?: string;
    to?: string;
  };
  function LinkTab(props: LinkTabProps) {
    const linkprops = omit(props, ["isActive"]);
    return <Tab component={Link} className={`${styles.tabHeader} ${styles.tabHeaderSelected}`} {...linkprops} />;
  }

  const getFileTypeName = (fileName: string) => {
    let result = "";
    if (!fileName) {
      return result;
    }

    // Check if the file name ends with "_I.pdf"
    if (fileName.endsWith("_I.pdf")) {
      result = "(Individual)";
    }
    // Check if the file name ends with "_C.pdf"
    else if (fileName.endsWith("_C.pdf")) {
      result = "(Combined)";
    }

    return result;
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Box className={classes.reportIcon}>
            <ArticleIcon
              className={classes.downloadcsv}
              fontSize="small"
              onMouseEnter={() => hoverIn({ params })}
            ></ArticleIcon>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Reporting Month",
      flex: 2,
      renderCell(params) {
        return (
          <Link
            color="var(--mui-palette-primary-main)"
            href="#"
            to="#"
            onClick={(event) => handleOpenLinkClick(event, params)}
          >
            {dayjs((params?.value as string).split("_")[1], "YYYYMMDD").format("MMMM YYYY")}{" "}
            {getFileTypeName(params?.value as string)}
          </Link>
        );
      },
    },
    {
      field: "providerno",
      headerName: "Provider Number",
      flex: 1,
    },
    {
      field: "date_created",
      headerName: "Created",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value).format("DD MMM YYYY") : ""),
    },
    {
      field: "first_viewed",
      headerName: "First Viewed",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value).format("DD MMM YYYY") : ""),
    },
    {
      field: "last_viewed",
      headerName: "Last Viewed",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value).format("DD MMM YYYY") : ""),
    },
    {
      field: "viewCount",
      headerName: "Report Viewed",
      flex: 1,
    },
    {
      field: "downloadprint",
      headerName: "Download/Print",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <SaveAltIcon
              className={classes.downloadcsv}
              fontSize="large"
              onClick={(event) => handleDownloadClick(event, params)}
            ></SaveAltIcon>
            <PrintOutlinedIcon
              onClick={(event) => printPdfHandler(event, params)}
              titleAccess={"Print"}
              color={"primary"}
              fontSize={"large"}
              fontWeight={300}
              className={styles.downloadcsv}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box>
        <AppBar elevation={0} className={styles.headerBar}>
          <MainNavigation
            subHeader="SKIN EXCISION EVALUATION PROGRAM"
            showBackButton
            mainHeader={CpdStyledHeader()}
            backText="HOME"
            backPath="/cpd-programs"
            hideSeparator={true}
            isCpd
            displayACL={isDesktop ? true : false}
          />
        </AppBar>
        <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />
        <Tabs
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
          value={tabPage}
          aria-label="Skin Evaluation program"
          className={styles.tabPanel}
        >
          <LinkTab isActive={tabPage === 0} label="View Skin Reports" to="#" />
        </Tabs>
      </Box>

      <Grid container sx={{ overflowY: "hidden", overflowX: "hidden", ml: 2, mr: 2, mt: 0, width: "auto" }}>
        <Grid item xs={12} sx={{ mt: 2 }}></Grid>
        <Grid item xs={11} md={6}>
          <Box sx={{ mr: 1, ml: 1, display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Report Start Date"
                value={reportStartDate as Dayjs | null | undefined}
                onChange={(newValue: Dayjs | null) => {
                  handleStartDateChange(newValue as Date | null);
                }}
                slotProps={{ textField: { size: "small", fullWidth: true, style: { backgroundColor: "#FFF" } } }}
                className={`${styles.DateInput}`}
              />
              <DatePicker
                format="DD/MM/YYYY"
                label="Report End Date"
                value={reportEndDate as Dayjs | null | undefined}
                onChange={(newValue: Dayjs | null) => {
                  handleEndDateChange(newValue as Date | null);
                }}
                slotProps={{ textField: { size: "small", fullWidth: true, style: { backgroundColor: "#FFF" } } }}
                className={`${styles.DateInput}`}
              />
            </LocalizationProvider>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={`${styles.submitButton}`}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box
            sx={isDesktop ? { ml: 2 } : { m: 2, p: 1 }}
            style={{
              backgroundColor: theme.colorSchemes.light.palette.primary.dark,
              height: "45px",
              color: "var(--mui-palette-grey-100)",
              display: "flex",
              alignItems: "center",
              width: isDesktop ? "380px" : "250px",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant={isDesktop ? "body1" : "body2"}
              style={{ color: theme.colorSchemes.light.palette.text.primary }}
            >
              Current number of Histology Samples this year: {reportCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mr: 1, ml: 1, minHeight: "70vh" }}>
          <Modal
            sx={hideIframe ? { display: "none" } : {}}
            open={typeof pdfUrl === "string" && pdfUrl.length > 0 && hideIframe == false}
            onClose={() => {
              setPdfUrl("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src={pdfUrl}
              ref={iframeRef}
              style={{
                width: "60%",
                height: "90%",
              }}
            ></iframe>
          </Modal>
          {reportLinkError && <ErrorPopover />}
          {typeof pdfUrl === "string" && pdfUrl.length > 0 && hideIframe == true && (
            <div>
              <iframe
                src={pdfUrl}
                ref={iframeRef}
                style={{
                  width: "60%",
                  height: "90%",
                }}
                hidden
              ></iframe>
            </div>
          )}

          <Box style={{ height: "100%", width: "100%", border: "none" }}>
            {isDesktop ? (
              <DataGrid
                sx={{
                  ".hightBackground": { background: "var(--mui-palette-primary-light)" },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                  },
                  "& .MuiDataGrid-main & .MuiDataGrid-root": {
                    display: "none",
                  },
                  "& .MuiTablePagination-select": { paddingRight: "24px" },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                }}
                getRowClassName={(params) => {
                  return params.row.viewCount == 0 ? "hightBackground" : "";
                }}
                paginationModel={{ page: tableDataPage, pageSize: pageSize }}
                onPaginationModelChange={(paginationModel) => {
                  setTableDataPage(paginationModel.page);
                  setPageSize(paginationModel.pageSize);
                }}
                disableColumnMenu
                rows={tableData}
                columns={columns}
                paginationMode="server"
                rowCount={tableDataCount}
                loading={loading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            ) : (
              <Box>
                {tableData.map((data, index) => (
                  <Card sx={{ marginBottom: "20px" }} key={index}>
                    <CardContent className={styles.mobileCard}>
                      <Box className={`${styles.mobileRow} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Box>
                          <ArticleIcon className={classes.downloadcsv} fontSize="small"></ArticleIcon>
                        </Box>
                        <Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2">Reporting Month</Typography>
                            <Typography variant="body2">
                              {dayjs((data.name as string).split("_")[1], "YYYYMMDD").format("MMMM YYYY")}{" "}
                              {getFileTypeName(data.name as string)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Created</Typography>
                        <Typography variant="body2">
                          {data.date_created ? dayjs(data.date_created).format("DD MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">First Viewed</Typography>
                        <Typography variant="body2">
                          {data.first_viewed ? dayjs(data.first_viewed).format("DD MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Last Viewed</Typography>
                        <Typography variant="body2">
                          {data.last_viewed ? dayjs(data.last_viewed).format("DD MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Report Viewed</Typography>
                        <Typography variant="body2">{data.viewCount}</Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewCount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Download/Print</Typography>
                        <Box>
                          <SaveAltIcon
                            className={classes.downloadcsv}
                            fontSize="large"
                            onClick={(event) => handleDownloadClick(event, data)}
                          ></SaveAltIcon>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}

            {reportListError && <ErrorPopover />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CPDskinPage;
