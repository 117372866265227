import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import authService from "../../services/authService";
import { withRouter, RouteComponentProps } from "react-router";
import AppContext from "../../context/AppContext";
import MainNavigation from "../MainNavigation/MainNavigation";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import styles from "../MFAEnrolment/MFAEnrolment.module.css";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";
import { useApolloClient, useMutation, useLazyQuery, OperationVariables } from "@apollo/client";
import { GenerateMfaToken, GenerateMfaTokenVariables } from "./types/GenerateMfaToken";
import { VerifyMfaToken, VerifyMfaTokenVariables } from "../MFAEnrolment/types/VerifyMfaToken";
import { TrustBrowser } from "../MFAEnrolment/types/TrustBrowser";
import GenerateMfaTokenMutation from "./GenerateMfaTokenMutation";
import VerifyMFATokenMutation from "../MFAEnrolment/VerifyMFATokenMutation";
import TrustBrowserQuery from "../MFAEnrolment/TrustBrowserMFAQuery";
import DialogBoxMFA from "../DialogBox/DialogBoxMFA";
import Divider from "@mui/material/Divider";
import * as MFATypes from "../../model/MFATypes";
import MFAInput from "./MFAInput";
import { storeMobileDeviceToken } from "../LoginPage/storeMobileDeviceToken";
import {
  UpdateUserDeviceSettings,
  UpdateUserDeviceSettingsVariables,
} from "../LoginPage/types/UpdateUserDeviceSettings";
import UpdateUserDeviceSettingsMutation from "../LoginPage/UpdateUserDeviceSettingsMutation";
import { getCpdProviders } from "../LoginPage/types/getCpdProviders";
import GetCpdProvidersQuery from "../LoginPage/GetCpdProvidersQuery";

const MFAAuthentication: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const isDesktop = useContext(AppContext).isDesktop;
  const [codeAuth, setCodeAuth] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [authCode, setAuthCode] = useState(0);
  const [lockError, setLockError] = useState(false);
  const [lockMessage, setLockMessage] = useState("");
  const scrollRef = useRef<HTMLElement>(null);
  const client = useApolloClient();

  const mfaToken = jwt.decode(authService.fetchToken()?.accessToken || "") as MFATypes.MFAToken;

  const isTrustBrowserEnabled = mfaToken.hospid ? mfaToken.hospitalMfaSetup.trustBrowserEnabled : true;
  const enrolments = mfaToken.userMfaSetup.enrolments;

  const authApp = Object.values(enrolments.filter((el) => el.status === 2))[0].mfaId;

  const runScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (authApp !== 2) {
      generateAndSendOtp(authApp, false);
    }
    runScroll();
    window.addEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    runScroll();
  }

  const authTypes = [
    {
      typeid: 2,
      title: "Use an Authenticator app",
      buttonText: "Confirm/Link",
      icon: <QrCode2Icon />,
    },
    {
      typeid: 3,
      title: "Email me a security code",
      buttonText: "Confirm/Link",
      icon: <EmailIcon />,
    },
    {
      typeid: 4,
      title: "SMS me a security code",
      buttonText: "Confirm/Link",
      icon: <PhoneIphoneIcon />,
    },
  ];

  const [generateMfaToken, { loading: loadingGenerateMfaToken }] = useMutation<
    GenerateMfaToken,
    GenerateMfaTokenVariables
  >(GenerateMfaTokenMutation, { fetchPolicy: "no-cache" });

  const [VerifyMFAToken, { loading: loadingVerifyMFAToken }] = useMutation<VerifyMfaToken, VerifyMfaTokenVariables>(
    VerifyMFATokenMutation,
  );

  const [updateUsersDeviceConfig] = useMutation<UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables>(
    UpdateUserDeviceSettingsMutation,
  );

  const [cpdProviders] = useLazyQuery<getCpdProviders, OperationVariables>(GetCpdProvidersQuery);

  const [getTrustBrowserToken] = useLazyQuery<TrustBrowser>(TrustBrowserQuery);

  const handleCodeAuthText = (otp: any, digit: number, mfaId: number, trustBrowser: boolean) => {
    setCodeAuth(otp);

    if (otp.length === digit) {
      handleAuthentication(mfaId, otp, trustBrowser);
    }
  };

  const goToLogin = async () => {
    authService.clearAll();
    await client.resetStore();
    history.push("/login");
  };

  const handleAuthentication = async (typeid: number, otp: string, trustBrowser: boolean) => {
    try {
      await VerifyMFAToken({
        variables: {
          input: {
            mfaId: typeid,
            token: otp,
          },
        },
      })
        .then((response) => {
          return response && response.data && response.data.verifyMfaToken;
        })
        .then(async (data) => {
          if (data && data.accessToken && data.accessToken.length > 0) {
            const t = jwt.decode(data.accessToken) as MFATypes.MFAToken;

            if (t && t.otpVerified) {
              if (trustBrowser) {
                const res = await getTrustBrowserToken();
                const data = res?.data?.trustBrowser.data;
                if (data) {
                  const { token, period } = data;
                  const maxAge = period * 60 * 60 * 24;
                  //!!!expires could conflict with different browser, use max-age instead
                  document.cookie = `trustBrowser_${authService.getUsername()}=${token};max-age=${maxAge};path=/;Session=false;`;
                  if (authService.getPrimaryEmail()) {
                    document.cookie = `trustBrowser_${authService.getPrimaryEmail().toUpperCase()}=${token};max-age=${maxAge};path=/;Session=false;`;
                  }
                }
              }
              authService.clearToken();
              authService.persistToken(data.accessToken);
              const from = authService.getLastViewed() || "/patients";
              authService.clearLastViewed();

              await storeMobileDeviceToken(updateUsersDeviceConfig, cpdProviders);

              history.replace(from);
            } else {
              setCodeAuth("");
              setIsError(true);
              setErrorMessage("Your security code has expired or is incorrect. Please try again.");
            }
          } else {
            setCodeAuth("");

            if (data?.message === "Account is Locked" || data?.message === "Max Attempt Reached") {
              setLockError(true);
              setLockMessage(data?.message);
            }

            setIsError(true);
            setErrorMessage("Your security code has expired or is incorrect. Please try again.");
          }
        });
    } catch (err) {
      setIsError(true);
      setErrorMessage("Error");
      // Possible errors
      // - options validation
      // - "Invalid input - it is not base32 encoded string" (if thiry-two is used)
      console.error(err);
    }
  };

  const generateAndSendOtp = async (mfaid: number, isResend: boolean) => {
    setErrorMessage("");

    if (mfaid === 2) {
      return;
    }

    await generateMfaToken({
      variables: {
        input: {
          mfaId: mfaid,
        },
      },
    })
      .then((response) => {
        return response.data?.generateMfaToken;
      })
      .then((data) => {
        if (!data?.tokenGenerated) {
          setIsError(true);

          const deliveryMode = mfaid === 3 ? "email" : "SMS";
          let userMessage = `Failed to send security code via ${deliveryMode}, please try again`;

          if (data?.message === "Message not queued") {
            userMessage = `Our services are currently undergoing maintenance. Please try again later`;
          }

          setErrorMessage(userMessage);
        } else {
          if (isResend) {
            setAuthCode(mfaid);
          }
        }
      });
  };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
        <Grid item xs={12}>
          <AuthLayout title="" maxWidth={"690px"}>
            <Box mt={4}>
              <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12}>
                  <Typography className={styles.mfaHeading} ref={scrollRef}>
                    Please enter a security code to authenticate your account.
                  </Typography>
                </Grid>

                {authCode ? (
                  <Fragment key={authCode}>
                    <MFAInput
                      mfaId={authCode}
                      handleAuthentication={handleAuthentication}
                      loading={loadingVerifyMFAToken}
                      handleCodeAuthText={handleCodeAuthText}
                      otpCode={codeAuth}
                      isTrustBrowser={isTrustBrowserEnabled}
                    />
                  </Fragment>
                ) : (
                  <Fragment key={authApp}>
                    <MFAInput
                      mfaId={authApp}
                      handleAuthentication={handleAuthentication}
                      loading={loadingVerifyMFAToken}
                      handleCodeAuthText={handleCodeAuthText}
                      otpCode={codeAuth}
                      isTrustBrowser={isTrustBrowserEnabled}
                    />

                    {enrolments.filter((el) => el.status === 2).length > 1 && (
                      <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={7}>
                          <Divider className={styles.divider}>OR</Divider>
                        </Grid>
                      </Grid>
                    )}

                    {enrolments &&
                      enrolments
                        .filter((el) => el.status === 2)
                        .map((enrol) => {
                          if (enrol.mfaId !== authApp) {
                            const type = authTypes.find((el) => el.typeid === enrol.mfaId);
                            return (
                              <Fragment key={enrol.mfaId}>
                                <Grid
                                  item
                                  xs={9}
                                  md={6}
                                  alignContent="center"
                                  alignItems="baseline"
                                  className={styles.highlight}
                                >
                                  {type && (
                                    <Grid container direction="row" alignItems="center">
                                      <Grid item>{type.icon}</Grid>
                                      <Grid item>
                                        <Typography className={styles.verticalSet}>{type.title}</Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={styles.button}
                                    onClick={() => generateAndSendOtp(enrol.mfaId, true)}
                                    disabled={loadingGenerateMfaToken}
                                  >
                                    {loadingGenerateMfaToken ? (
                                      <Box width={"100%"} textAlign="center">
                                        <CircularProgress size={16} />
                                      </Box>
                                    ) : (
                                      <span>Send</span>
                                    )}
                                  </Button>
                                </Grid>
                              </Fragment>
                            );
                          }
                        })}
                  </Fragment>
                )}

                {isError && (
                  <Grid item xs={12} md={12}>
                    <Typography className={styles.errorMessage}>{errorMessage}</Typography>
                  </Grid>
                )}
              </Grid>

              <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  className={styles.button}
                  // startIcon={<AddCircleOutlineIcon />}
                  onClick={goToLogin}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </AuthLayout>
        </Grid>
      </Grid>
      <DialogBoxMFA open={lockError} onClose={goToLogin} message={lockMessage}></DialogBoxMFA>
    </Fragment>
  );
};

export default withRouter(MFAAuthentication);
