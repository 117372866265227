import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import authService from "../../services/authService";
import { useMutation, useQuery } from "@apollo/client";
import { GetSMSCLink, GetSMSCLink_getSMSCLink_data } from "./types/GetSMSCLink";
import getSMSCLinkQuery from "./getSMSCLinkQuery";
import Loading from "../Loading/Loading";
import { ClearSMSCLink } from "./types/ClearSMSCLink";
import clearSMSCLinkMutation from "./clearSMSCLinkMutation";
import { UpdateSMSCLink, UpdateSMSCLinkVariables } from "./types/UpdateSMSCLink";
import updateSMSCLinkMutation from "./updateSMSCLinkMutation";
import { QueueDownloadClinicIds, QueueDownloadClinicIdsVariables } from "./types/QueueDownloadClinicIds";
import queueDownloadClinicIdsMutation from "./queueDownloadClinicIdsMutation";
import AppContext from "../../context/AppContext";
import { isTenantVet } from "../../services/tenantService";
import styles from "../EorderDialog/EorderDialog.module.css";
import FeedbackMutation from "../Feedback/FeedbackMutation";
import { UserFeedback, UserFeedbackVariables } from "../Feedback/types/UserFeedback";
import { useDialogStyles } from "./styles/dialogStyles";
import { LoadingButton } from "@mui/lab";
import { GetEdiLink } from "./types/GetEdiLink";
import getEdiLinkQuery from "./getEdiLinkQuery";
import { eResultsOptions, getSiteOption } from "../../utils/siteOptions";

interface DialogBoxShareSMSCProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  reports: Record<string, boolean>;
}

const useStyles = useDialogStyles;

const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

const DialogBoxShareSMSC: React.FC<DialogBoxShareSMSCProps> = (props) => {
  const classes = useStyles();
  const shareEdiLinks = (getSiteOption(eResultsOptions.SHARE_SMSC_EDI_LINKS) as any) || null;

  const { setSuccess } = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [selectedClinicIds, setSelectedClinicIds] = React.useState<string[]>([]);

  // const [currentDialogOpen, setCurrentDialogOpen] = React.useState<boolean>(false);
  const [smscLinks, setSmscLinks] = React.useState<GetSMSCLink_getSMSCLink_data[]>([]);
  const [noSMSCMessage, setNoSMSCMessage] = React.useState<string>("");

  // const [ediLinks, setEdiLinks] = React.useState<GetEdiLink_getEdiLink[] | string[]>([]);

  const [clearPreferredSMSC] = useMutation<ClearSMSCLink>(clearSMSCLinkMutation);

  const [updatePreferredSMSC] = useMutation<UpdateSMSCLink, UpdateSMSCLinkVariables>(updateSMSCLinkMutation, {
    variables: {
      input: {
        clinicIds: selectedClinicIds,
      },
    },
  });
  const [sendFeedback] = useMutation<UserFeedback, UserFeedbackVariables>(FeedbackMutation);
  const [queueDownloadClinicIds, { loading: loadingQueue }] = useMutation<
    QueueDownloadClinicIds,
    QueueDownloadClinicIdsVariables
  >(queueDownloadClinicIdsMutation, {
    variables: {
      input: {
        reportIds: Object.keys(props.reports ?? {}).map((key) => parseInt(key)),
        clinicIds: selectedClinicIds,
      },
    },
  });

  const { data: smscLinkData, loading: smscLinkLoading } = useQuery<GetSMSCLink>(getSMSCLinkQuery, {
    fetchPolicy: "no-cache",
  });

  const { data: ediLinkData } = useQuery<GetEdiLink>(getEdiLinkQuery, {
    fetchPolicy: "no-cache",
  });

  const getMessage = (jsonData: any, keys: string[]) => {
    const foundMessages = [];

    // Iterate through the keys array
    for (const key of keys) {
      // Iterate through the unsupportedLinks array in the JSON
      for (const item of jsonData.unsupportedLinks) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          foundMessages.push(item[key]); // Collect messages for found keys
        }
      }
    }

    // If more than one key is found or no key found, return the default message
    if (foundMessages.length > 1 || foundMessages.length === 0) {
      for (const item of jsonData.unsupportedLinks) {
        if (Object.prototype.hasOwnProperty.call(item, "default")) {
          return item.default; // Return the default message
        }
      }
    } else {
      return foundMessages[0];
    }
    return ""; // Return undefined if no key or default is found
  };

  useEffect(() => {
    if (smscLinkData) {
      if (smscLinkData.getSMSCLink.data.length === 0) {
        setPage(3);
      }
      setSmscLinks(smscLinkData.getSMSCLink.data);
      const smscPreferredData = smscLinkData.getSMSCLink.data.filter((smscLink) => smscLink.preferred);
      const selected = smscPreferredData ? smscPreferredData.map((smsc) => (smsc.clinicid ? smsc.clinicid : "")) : [];

      setSelectedClinicIds(selected);
      if (selected.length > 0) {
        setPage(2);
      }
    }

    if (ediLinkData && shareEdiLinks) {
      const ediLinks: string[] = ediLinkData.getEdiLink.data;
      if (!shareEdiLinks.value.supportedLinks.some((item: string) => ediLinks.includes(item))) {
        //Non supported links found.
        setNoSMSCMessage(getMessage(shareEdiLinks.value, ediLinks));
      }
    }
  }, [smscLinkData, ediLinkData]);

  const onClose = () => {
    if (smscLinks.filter((smscLink) => smscLink.preferred).length > 0) {
      setPage(2);
    } else if (smscLinks.length == 0) {
      setPage(3);
    } else {
      setPage(1);
    }
    props.onClose();
  };

  const useOnce = () => {
    if (selectedClinicIds.length > 0) {
      clearPreferredSMSC();
      const tempSmscLinks = smscLinks.map((smscLink: GetSMSCLink_getSMSCLink_data) => {
        return { ...smscLink, preferred: false };
      });
      setSmscLinks(tempSmscLinks);
      setPage(2);
    }
  };

  const useAlways = () => {
    if (selectedClinicIds.length > 0) {
      updatePreferredSMSC({
        variables: {
          input: {
            clinicIds: selectedClinicIds,
          },
        },
      });
      const tempSmscLinks = smscLinks.map((smscLink: GetSMSCLink_getSMSCLink_data) => {
        const preferred = selectedClinicIds.includes(smscLink.clinicid || "");
        return { ...smscLink, preferred: preferred };
      });
      setSmscLinks(tempSmscLinks);
      setPage(2);
    }
  };

  const doQueueDownloads = () => {
    queueDownloadClinicIds({
      variables: {
        input: {
          reportIds: Object.keys(props.reports ?? {}).map((key) => parseInt(key)),
          clinicIds: selectedClinicIds,
        },
      },
    }).then(() => {
      setSuccess("Queued downloads for selected SMSC links");
      if (smscLinks.filter((smscLink) => smscLink.preferred).length > 0) {
        setPage(2);
      } else {
        setPage(1);
      }
      onClose();
    });
  };

  const sendRequestSMSC = async () => {
    const state = authService.getState() ? authService.getState() : "";
    const providers: string[] = authService.getProviders();
    const selectedProvider = providers[0];
    const name: string = authService.getName();

    await sendFeedback({
      variables: {
        input: {
          providerno: selectedProvider,
          phone: "",
          email: "",
          name: name,
          surname: "",
          type: "usersupport-ACL",
          text: "Please enable SMSC Link for my account",
          state: state,
          source: (isTenantVet() ? "Gribbles " : "") + "eResults",
        },
      },
    });
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBoxBig }}
        className={styles.sizeDialogDesltop}
      >
        <DialogTitle className={styles.dialogTitleWithMargin}>
          <DialogBoxTitle
            title={page === 1 || page === 3 ? "Select your SMSC Link" : "Request Submitted"}
            width={450}
            closeButton
            handleClose={onClose}
            marginLeft={-3}
            closeButtonNoRightPadding
          />
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          {page === 1 && (
            <>
              <Box m={2}>
                <Typography>
                  We have the following SMSC link(s) associated with your account. Please select a SMSC link from below
                  to send results to your practice management software:
                </Typography>
              </Box>
              {smscLinkLoading && <Loading />}
              {smscLinks.map((smscLink) => {
                const clinicId = smscLink.clinicid;
                return (
                  <Box m={2} key={`chk_clinic_id_${clinicId}`}>
                    <FormControlLabel
                      labelPlacement="end"
                      value={clinicId}
                      control={<Checkbox checked={selectedClinicIds.includes(clinicId || "")} />}
                      label={clinicId}
                      onChange={(event: any) => {
                        if (clinicId) {
                          if (event.target.checked) {
                            setSelectedClinicIds([...selectedClinicIds, clinicId].filter(onlyUnique));
                          } else {
                            setSelectedClinicIds(selectedClinicIds.filter((id) => id !== clinicId).filter(onlyUnique));
                          }
                        }
                      }}
                    />
                  </Box>
                );
              })}
            </>
          )}
          {page == 2 && (
            <>
              <Box m={2}>
                <Typography>
                  By clicking ‘Send’ you intend to share the selected results to the following SMSC link:
                </Typography>
              </Box>
              {selectedClinicIds.map((clinicId) => {
                return (
                  <Box m={2} key={`t_clinic_id_${clinicId}`}>
                    <Typography>{clinicId}</Typography>
                  </Box>
                );
              })}
            </>
          )}

          {page == 3 && (
            <>
              {noSMSCMessage === "" ? (
                <>
                  <Box m={2}>
                    <Typography>
                      We have no SMSC links associated with your account. <u> Share via email?</u>
                    </Typography>
                  </Box>
                  <Box m={2}>
                    <Typography>
                      To enable this feature please click ‘Request SMSC Link’ below and our eHealth Client IT team will
                      contact you shortly.
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box m={2}>
                  <Typography>{noSMSCMessage}</Typography>
                </Box>
              )}
            </>
          )}

          {page == 4 && (
            <Box m={2}>
              <Typography>
                Your request has been sent successfully. Our eHealth Client IT team will contact you shortly.
              </Typography>
            </Box>
          )}

          {page === 1 && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
              <Button
                onClick={() => {
                  props.onBack();
                }}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
              <Button onClick={useOnce} variant="contained" color="primary" disabled={selectedClinicIds.length === 0}>
                Use Once
              </Button>

              <Button onClick={useAlways} variant="contained" color="primary" disabled={selectedClinicIds.length === 0}>
                Use Always
              </Button>
            </Box>
          )}

          {page === 2 && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
              <Button
                onClick={() => {
                  setPage(1);
                }}
                variant="contained"
                color="secondary"
              >
                Set up SMSC
              </Button>

              <LoadingButton
                onClick={doQueueDownloads}
                variant="contained"
                color="primary"
                loading={loadingQueue}
                disabled={loadingQueue}
              >
                Send to PMS
              </LoadingButton>
            </Box>
          )}
          {page === 3 && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
              <Button
                onClick={() => {
                  props.onBack();
                }}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
              {noSMSCMessage === "" ? (
                <Button
                  onClick={() => {
                    sendRequestSMSC();
                    setPage(4);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Request SMSC Link
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    props.onClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Close
                </Button>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogBoxShareSMSC;
